
import React, { useMemo, useCallback } from 'react';
import { Table, Button, Icon, Dimmer } from 'tabler-react';
import moment from 'moment';
import { confirm } from '../Dialog/Confirm.js';

// function formatMinutes(minutes) {
//   if (minutes === 1) {
//     return '1 minute';
//   } else if (minutes < 60) {
//     return `${minutes} minutes`;
//   } else if (minutes === 60) {
//     return '1 hour';
//   } else {
//     return minutes % 60 === 0 ?
//       `${Math.floor(minutes / 60)} hours` :
//       `${(minutes / 60.0).toFixed(1)} hours`;
//   }
// }

const VolunteerHourList = ({ hours, organizations, onEdit, onDelete, loading }) => {
  const confirmDelete = useCallback(hour => {
    confirm('Are you sure you wish to delete this volunteer entry?', 'Delete!?').then(() => {
      onDelete(hour);
    }).catch(() => {});
  }, []);
  
  const items = useMemo(() => {
    return hours.map(hour => {
      const organization = organizations.find(org => org.uuid === hour.organization_uuid);

      return {
        key: hour.uuid,
        item: [
          { content: moment(hour.date).format('MMMM DD, YYYY') },
          { content: organization ? organization.company : '...' },
          { content: moment.duration(hour.minutes, 'minutes').humanize() },
          { content: (
            <Button.List>
              <Button outline color='warning' size='sm' onClick={() => onEdit(hour)}><Icon name='edit' /></Button>
              <Button outline color='danger' size='sm' onClick={() => confirmDelete(hour)}><Icon name='trash' /></Button>
            </Button.List>
          )}
        ]
      };
    });
  }, [hours, organizations]);

  return (
    <Dimmer active={loading} loader={loading}>
      <Table
        headerItems={[{ content: 'Date' }, { content: 'Organization' }, { content: 'Hours' }, { content: 'Actions' }]}
        bodyItems={items}
        />
    </Dimmer>
  );
}

export default VolunteerHourList;
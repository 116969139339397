
import React, { useCallback } from 'react';
import { Page } from 'tabler-react';
import EventCard from '../components/EventCard.js';
import { deleteEvent } from '../API.js';
import { confirm } from '../components/Dialog/Confirm.js';

const del = event =>
  confirm(`Are you sure you wish to delete this (${event.title}) event?`, 'Delete')
    .then(() => deleteEvent(event.uuid));
    // .then(result => location.reload()); // eslint-disable-line

export default function EventPage({event, uuid, history, ...props}) {

  const onDelete = useCallback((start, finish) => {
    start();
    del(event)
      .then(() => history.replace('/events'))
      .then(finish)
      .catch(finish);
  }, [event, history]);

  return (
    <Page.Content title='Event'>
      <EventCard event={event} onDelete={onDelete} />
    </Page.Content>
  );
}


import React, { useState, useCallback, useEffect } from 'react';
import { Card, Button, Icon, Form, Grid, Dimmer } from 'tabler-react';
import moment from 'moment';

const DEFAULT = {
  minutes: 0,
  date: moment().format(),
  organization_uuid: null
};

const VolunteerHourForm = ({ hour = DEFAULT, organizations = [], loading, onSave, onClose }) => {
  const [data, setData] = useState(hour);
  const isEditing = Boolean(hour && hour.uuid);
  const isValid = Boolean(data.minutes && data.date && data.organization_uuid);

  useEffect(() => {
    setData({ ...DEFAULT, ...hour });
  }, [hour]);

  const setMinutes = useCallback(e => {
    const value = e.currentTarget.value;
    setData(data => {
      const hours = data.minutes ? Math.floor(parseInt(data.minutes) / 60) : 0;
      const minutes = (hours * 60) + parseInt(value);

      return {
        ...data,
        minutes
      };
    });
  }, []);

  const setHours = useCallback(e => {
    const value = e.currentTarget.value;
    setData(data => {
      const minutes = data.minutes ? parseInt(data.minutes) % 60 : 0;
      const total = (parseInt(value) * 60) + minutes;

      return {
        ...data,
        minutes: total
      };
    });
  }, []);

  const setDate = useCallback(e => {
    const value = e.currentTarget.value;
    setData(data => ({
      ...data,
      date: moment(value).format()
    }));
  }, []);

  const setForm = useCallback(key => e => {
    const value = e.currentTarget.value;
    setData(data => ({
      ...data,
      [key]: value
    }));
  }, []);

  return (
    <Card>
      <Card.Header>
        <Card.Title>{ isEditing ? 'Edit' : 'Add' } Hours</Card.Title>
        <Card.Options>
          { Boolean(onClose) && 
            <Button onClick={onClose} disabled={loading} color="warning" size="sm" className="ml-2">
              <Icon name='x' />
            </Button>
          }
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <Dimmer active={loading} loader={loading}>
          <Form.Select label='Organization' value={data.organization_uuid || ''} onChange={setForm('organization_uuid')}>
            <option disabled selected default value=''>Select an organization...</option>
            { organizations.map(org => (
              <option key={org.uuid} value={org.uuid}>{ org.company }</option>
            )) }
          </Form.Select>
          <Grid.Row>
            <Grid.Col>
              <Form.Select label='Hours' value={Math.floor(data.minutes / 60)} onChange={setHours}>
                { Array.from(Array(19).keys()).map(key => (
                  <option key={key} value={key}>{ key }</option>
                )) }
              </Form.Select>
            </Grid.Col>
            <Grid.Col>
              <Form.Select label='Minutes' value={data.minutes % 60} onChange={setMinutes}>
                { Array.from(Array(61).keys()).map(key => (
                  <option key={key} value={key}>{ key }</option>
                )) }
              </Form.Select>
            </Grid.Col>
          </Grid.Row>
          <Form.Input
            label='Date'
            type='date'
            value={moment(data.date).format('YYYY-MM-DD')}
            onChange={setDate}
            />
        </Dimmer>
      </Card.Body>
      <Card.Footer>
        <Button.List>
          <Button color='primary' onClick={() => onSave(data)} disabled={!isValid || loading}>{ isEditing ? 'Save' : 'Add' }</Button>
          <Button color='warning' onClick={onClose} disabled={loading}>Cancel</Button>
        </Button.List>
      </Card.Footer>
    </Card>
  );
}

export default VolunteerHourForm;

import React, { useState, useCallback, useRef } from 'react';
import { Card, Grid, Button } from 'tabler-react';
import Formsy from 'formsy-react';
import { Input } from './Form';

import { addSalesTeamMember } from '../API.js';

export default function AddSalesTeamMemberForm({ onAdd, onClose }) {
  const [valid, setValid] = useState();
  const [loading, setLoading] = useState();
  const form = useRef();

  const onSubmit = useCallback(async (data, reset) => {
    try {
      setLoading(true);
      const { member } = await addSalesTeamMember(data);
      onAdd(member);
      setLoading();
      reset();
    } catch (error) {
      console.error('AddSalesTeamMemberForm', error);
      setLoading();
    }
  }, []);

  const enable = useCallback(() => {
    setValid(true);
  }, []);

  const disable = useCallback(() => {
    setValid(false);
  }, []);

  return (
    <Card>
      <Card.Header>
        <Card.Title>Enter Sales Rep Info</Card.Title>
      </Card.Header>
      <Card.Body>
        <Formsy
          ref={ref => form.current = ref}
          onSubmit={data => console.log('onSumbit', data)}
          onValidSubmit={onSubmit}
          onValid={enable}
          onInvalid={disable}
          >
          <Grid.Row>
            <Grid.Col xl={6} lg={6} md={6} sm={12} xs={12} width={12}>
              <Input name='name.first' label='First Name' placeholder='First Name' required />
            </Grid.Col>
            <Grid.Col xl={6} lg={6} md={6} sm={12} xs={12} width={12}>
              <Input name='name.last' label='Last Name' placeholder='Last Name' required />
            </Grid.Col>
          </Grid.Row>
          <Input
            name='email'
            validations='isEmail'
            validationError='Invalid Email'
            label='Email'
            placeholder='salesrep@hnpabc.com'
            required
            />
          <Input name='password' label='Initial Password' required />
          <Input name='data.account_manager_token' label='URL Slug' />
          <Button.List>
            <Button type='submit' color='success' disabled={loading || !valid}>Add</Button>
            <Button color='danger' disabled={loading} onClick={onClose}>Cancel</Button>
          </Button.List>
        </Formsy>
      </Card.Body>
    </Card>
  );
}
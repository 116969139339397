
import React, { useContext, useState, useEffect } from 'react';
import { Page, Card, Dimmer, Button } from 'tabler-react';
import { AuthContext } from '../components/AuthContext.js';
import { getOwnListings } from '../API.js';
import useLoading from '../tools/useLoading.js';
import ListingsByZip from '../components/ListingsByZip.js';

export default function DashboardPage() {
  const [user, account] = useContext(AuthContext);
  const [listings, setListings] = useState([{ zip: '...' }, { zip: '...' }]);
  const { isLoading, start, finish } = useLoading();
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    start();
    getOwnListings().then(({ listings }) => {
      setListings(listings);
      finish();

      if (listings && listings.length && listings[0].zip) {
        setSelected(listings[0].zip);
      }
    }).catch(() => {
      setListings([]);
      finish();
    });
  }, [account]);

  return (
    <Page.Content title='Dashboard'>
      <Card>
        <Card.Header>
          <h2>Zip Codes</h2>
        </Card.Header>
        <Dimmer active={isLoading} loader={isLoading}>
          <Card.Body style={{minHeight: '1em'}}>
            <ol>
              { Boolean(listings) && listings.map(listing => (
                <li key={listing.uuid}>
                  <Button onClick={() => setSelected(listing.zip)} link>
                    <strong>{ listing.zip }</strong>
                  </Button>
                </li>
              ))}
            </ol>
          </Card.Body>
        </Dimmer>
      </Card>
      { Boolean(selected) &&
        <ListingsByZip zip={selected} />
      }
    </Page.Content>
  );
}


import React, { useState, useEffect, useMemo } from 'react';
import { Page } from 'tabler-react';

import TablerTable from '../components/Table/TablerTable.js';
import { gql, useQuery } from '@apollo/client';
import { CSVLink } from "react-csv";
import { format } from 'date-fns'

const query = gql`
  query($meeting_id: Float $referred: Boolean) {
    meetings {
      meeting_id
      location
    }
    meeting_attendees(meeting_id: $meeting_id referred: $referred) {
      occurrence {
        occurrence_id
        start
        duration
      }
      attendees {
        heard_from
        referred_by
        participant {
          name
          user_email
        }
      }
    }
  }
`;

const columns = [{
  Header: 'Name',
  accessor: 'participant.name'
}, {
  Header: 'Email',
  accessor: 'participant.user_email'
}, {
  Header: 'Heard From',
  accessor: 'heard_from'
}, {
  Header: 'Referrered By',
  accessor: 'referred_by'
}];

export default function ZoomAttendeesPage() {
  const [meetingId, setMeetingId] = useState('100815163');
  const [mustHaveReferral, setMustHaveReferral] = useState(false);

  const { loading, error, data } = useQuery(query, {
    variables: { meeting_id: parseInt(meetingId, 10), referrered: mustHaveReferral }
  });

  const meeting = useMemo(() => 
    data && data.meetings.find(m => m.meeting_id === parseInt(meetingId, 10)), 
    [data, meetingId]
  );

  const meetingDate = useMemo(() => {
    if (data) {
      const start = parseInt(data.meeting_attendees.occurrence.start, 10);
      const date = new Date(start);
      return format(date, 'MMMM do @ ha');
    }

    return '';
  }, [data]);

  const csvData = useMemo(() => {
    if (data) {
      return data.meeting_attendees.attendees.map(attendee => ({
        name: attendee.participant.name,
        email: attendee.participant.user_email,
        heard_from: attendee.heard_from,
        referred_by: attendee.referred_by,
        location: meeting ? meeting.location : '',
        date: meetingDate
      }));
    }
  }, [data, meeting]);

  return (
    <Page.Content title='Zoom Attendees'>
      { Boolean(data && data.meetings) && 
        <div className='form-group' style={{ padding: '2em' }}>
          <div className='form-label'>Meeting Location</div>
          <select className='form-control' value={meetingId} onChange={e => setMeetingId(e.target.value)}>
            <option default select>Select a Meeting</option>
            { data.meetings.map((m, i) => 
              <option key={i} value={m.meeting_id}>
                {m.location} ({m.meeting_id})
              </option>
            )}
          </select>
        </div>
      }
      {
        loading ? <p>Loading...</p> : null
      }
      {
        csvData ? 
          <CSVLink 
            className='btn btn-primary mb-2' 
            target='_blank' 
            filename={`${meetingId}-${meeting ? meeting.location.toLowerCase() : 'X'}-attendees.csv`} 
            data={csvData}>
              Download Data (CSV)
          </CSVLink> :
          null
      }
      <TablerTable
        title={`Attendees for ${meetingDate} (${meeting ? meeting.location : ''})`}
        data={data ? data.meeting_attendees.attendees : []}
        columns={columns}
        defaultPageSize={10}
        />
    </Page.Content>
  );
}

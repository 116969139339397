
import React, { useCallback, useState, useRef } from 'react';
import { withFormsy } from 'formsy-react';
import TagsInput from 'react-tagsinput';
import { Form, Icon } from 'tabler-react';

const RenderTag = ({tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other}) => (
  <Form.SelectGroupItem
    key={key}
    {...other}
    label={
      <>
        {getTagDisplayValue(tag)} <Icon name='x-circle' />
      </>
    }
    value={getTagDisplayValue(tag)}
    onClick={() => onRemove(key)}
  />
);

function Tags({ type, placeholder, getValue, setValue, convertValue, getErrorMessage, label, required, filter, ...props }) {
  const input = useRef();
  const [text, setText] = useState('');

  const onChangeInput = useCallback(value => {
    if (convertValue) {
      value = convertValue(value);
    }

    if (filter && filter(value)) {
      input.current.addTag(value);
    } else {
      setText(value);
    }
  }, [filter, convertValue]);

  const changeValue = useCallback((tags = []) => {
    if (filter) {
      return setValue(tags.filter(filter));
    }

    setValue(tags);
  }, [setValue, filter]);

  const renderLayout = useCallback((tagComponents, inputComponent) => {
    return (
      <span>
        <Form.Group isRequired={required} label={label} style={{marginBottom: 0}}>
          { Boolean(tagComponents && tagComponents.length) &&
            <Form.SelectGroup pills>
              {tagComponents}
            </Form.SelectGroup>
          }
        </Form.Group>
        { inputComponent }
      </span>
    );
  }, [label, required]);

  return (
    <TagsInput
      ref={ref => input.current = ref}
      inputProps={{
        type,
        placeholder,
        className: 'form-control'
      }}
      renderLayout={renderLayout}
      renderTag={RenderTag}
      {...props}
      className='form-group'
      value={getValue() || []}
      onChange={changeValue}
      inputValue={text}
      onChangeInput={onChangeInput}
      />
  );
}

const FormsyTags = withFormsy(Tags);
export default FormsyTags;

export function ZipTags(props) {
  return (
    <FormsyTags
      filter={(t) => /\b\d{5}\b/g.test(t)}
      convertValue={v => String(v).replace(/\D/g, '')}
      {...props}
      addKeys={[9, 13, 32]}
      addOnBlur
      addOnPaste
      onlyUnique
      />
  );
}

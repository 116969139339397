
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { withFormsy, addValidationRule } from 'formsy-react';
import { Form, Alert, Dimmer } from 'tabler-react';
import { getS3UploadUrl, getFile } from '../../API.js';

addValidationRule('maxSize', function(values, value, size) {
  const file = value[0];
  return file && file.size > size;
});

const FormsyFileInput = withFormsy(({ 
  getValue, setValue, getErrorMessage, max = 0, validTypes = [], renderFile, 
  label, ...props
}) => {
  const input = useRef();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState();
  const [invalidMessage, setInvalidMessage] = useState(false);
  const uuid = getValue();

  useEffect(() => {
    if (uuid && (!file || file.uuid !== uuid)) {
      setLoading(true);
      getFile(uuid).then(({file}) => {
        setFile(file);
        setLoading(false);
      });
    }
  }, [uuid, file]);

  const onProgress = useCallback(({loaded, total, lengthComputable}) => {
    console.log('onProgress', loaded, total, lengthComputable);
  }, []);

  const onComplete = useCallback((uuid, url) => {
    console.log('onComplete', uuid, url);
    setValue(uuid);
  }, [setValue]);

  const changeValue = useCallback((e) => {
    const { files } = e.currentTarget;

    const file = files[0];

    const _validTypes = [].concat(validTypes);
    if (_validTypes.length && _validTypes.indexOf(file.type) === -1) {
      setInvalidMessage(
        `Invalid file type, please select another.`
      );

      return;
    }

    if (max && file.size > max) {
      const megabytes = file.size / 1024 / 1024;
      const rounded = Math.round(megabytes * 100) / 100;

      setInvalidMessage(
        `The file is too large (${rounded}mb), please select another.`
      );

      return;
    }

    setInvalidMessage(false);
    getS3UploadUrl(file.name, file.type, file.size).then(result => {
      const { signedRequest, uuid, url } = result;
      const xhr = new XMLHttpRequest();

      xhr.open('PUT', signedRequest, true);
      xhr.addEventListener('progress', onProgress); // loaded, total // TODO work progress
      xhr.addEventListener('load', () => {
        onComplete(uuid, url);
        input.current && (input.current.value = ''); // clear input
        setUploading(false);
      });

      xhr.addEventListener('error', () => {
        setUploading(false);
      });

      setUploading(true);
      xhr.send(file);
    });
  }, [input, max, validTypes]);

  const onRemove = useCallback(() => {
    setFile(null);
    setValue(null);
    setUploading(false);
  }, []);

  return (
    <Form.Group isRequired={props.required} label={label}>
      { file && (renderFile ? renderFile(file, onRemove) : (
          <Alert type='info' icon='file' isDismissible onDismissClick={onRemove}>
            <a className='alert-link' href={file.url} target='_blank' rel='noopener noreferrer'>
              { file.key || 'File' }
            </a>
          </Alert>
        ))
      }
      <Dimmer active={uploading} loader={uploading}>
        { !file && (
          <Form.FileInput
            {...props}
            key={file}
            ref={ref => input.current = ref}
            disabled={uploading}
            onChange={changeValue}
            />
          )
        }
        { !file && getErrorMessage() &&
          <div style={{ marginTop: '1em'}}>
            <Alert type='danger' icon='alert-triangle'>
              { getErrorMessage() }
            </Alert>
          </div>
        }
        { !file && invalidMessage &&
          <div style={{ marginTop: '1em'}}>
            <Alert type='danger' icon='alert-octagon' isDismissible onDismissClick={() => setInvalidMessage(false)}>
              { invalidMessage }
            </Alert>
          </div>
        }
      </Dimmer>
    </Form.Group>
  );
});

const ImageUploadInput = (props) => (
  <FormsyFileInput 
    validTypes={['image/jpeg', 'image/png', 'image/gif']} 
    max={4194304} 
    {...props} 
    />
);

// TODO add support (conversion?) for Apple .mov (video/quicktime) files
const VideoUploadInput = (props) => (
  <FormsyFileInput 
    validTypes={['video/mp4']} 
    max={104857600} 
    {...props} 
    />
);

export default FormsyFileInput;

export {
  ImageUploadInput,
  VideoUploadInput
};

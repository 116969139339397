import React from 'react';

const StandaloneFormPage = (props) => (
  <div className='page'>
    <div className='page-single'>
      <div className='container'>
        <div className='row'>
          <div className='col col-login mx-auto'>
            { props.imageURL &&
              <div className='text-center mb-6'>
                <img src={props.imageURL} className='h-9' alt='logo' />
              </div>
            }
            {props.children}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default StandaloneFormPage;


import React, { useState, useCallback } from 'react';
import { SkyLightStateless } from 'react-skylight';
import { confirmable, createConfirmation } from 'react-confirm';
import { Card, Button } from 'tabler-react';
import Formsy from 'formsy-react';

import { TextArea } from '../Form';
import { createNote } from '../../API.js';
import useLoading from '../../tools/useLoading.js';

export const AddNote = confirmable(({show, proceed, dismiss, account_uuid, name}) => {
  const { loading, start, finish } = useLoading();
  const [valid, setValid] = useState(false);

  const onSubmit = useCallback(async (data, reset) => {
    try {
      start();
      const { note } = await createNote({...data, account_uuid});
      finish();
      dismiss();
      proceed(note);
    } catch(error) {
      finish();
    }
  }, []);

  return (
    <SkyLightStateless
      onCloseClicked={dismiss}
      onOverlayClicked={dismiss}
      isVisible={show}
      dialogStyles={{minHeight: 10}}
      closeButtonStyle={{display: 'none'}}
      >
      <style>{'.mb0 { margin-bottom: 0; }'}</style>
      <Card className='mb0'>
        <Formsy
          onValidSubmit={onSubmit}
          onValid={() => setValid(true)}
          onInvalid={() => setValid(false)}
          >
          <Card.Body>
            <h4>Add Note for: {typeof name === 'string' ? name : account_uuid}</h4>
            <TextArea name='message' label='Message' placeholder='Enter note here...' required />
          </Card.Body>
          <Card.Footer>
            <Button.List>
              <Button type='submit' color='success' disabled={loading || !valid}>Add</Button>
              <Button color='danger' disabled={loading} onClick={dismiss}>Cancel</Button>
            </Button.List>
          </Card.Footer>
        </Formsy>
      </Card>
    </SkyLightStateless>
  );
});

const showAddNote = createConfirmation(AddNote);
export const addNote = (account_uuid, name) => {
  console.warn('showAddNote name', name);
  return showAddNote({ account_uuid, name });
}

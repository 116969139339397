
import React, { useState, useEffect, useMemo } from 'react';
import { getSalesTeam } from '../../API.js';
import Select from './Select.js';

export default function SalesTeamSelect({ alphabetical, ...props }) {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    getSalesTeam().then(({ members }) => {
      if (alphabetical) {
        members.sort((a, b) => {
          const nameA = String(a.name ? a.name.first : a.company).toLowerCase();
          const nameB = String(b.name ? b.name.first : b.company).toLowerCase();
  
          return nameA.localeCompare(nameB);
        });
      }

      setMembers(members);
    });
  }, [alphabetical]);

  const options = useMemo(() => members.map(member => ({
    key: member.uuid,
    value: member.uuid,
    label: member.name ?
      member.name.first + ' ' + member.name.last :
      member.company
  })), [members]);

  return (
    <Select
      label='Sales Rep'
      placeholder='Select a Sales Rep...'
      options={options}
      {...props}
      />
  );
}

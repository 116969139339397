
import React, { useState, useEffect, useCallback } from 'react';
import { Page, Card } from 'tabler-react';
import { getBusinessWithListings, updateListingsForAccount } from '../API.js';
import ListingCard from '../components/ListingCard.js';
import UpdateListingCard from '../components/UpdateListingCard.js';
import useLoading from '../tools/useLoading.js';

export default function BusinessPage({ uuid, business: biz, listings: lstngs }) {
  const { isLoading, start, finish } = useLoading();

  const [business, setBusiness] = useState(biz);
  const [listings, setListings] = useState(lstngs || []);

  const refresh = useCallback(() => {
    start();
    getBusinessWithListings(uuid).then(({ business }) => {
      setBusiness(business);
      setListings(business.listings || []);
      finish();
    }).catch(finish);
  }, [uuid]);

  const updateListings = useCallback((update, start, finish) => {
    console.log('updateListings', update);
    start();
    updateListingsForAccount(uuid, update).then(({listings}) => {
      finish();
      console.log('updateListings response', listings);
      setListings(listings);
      setBusiness({...business, listings});
    })
  }, [uuid]);

  useEffect(() => {
    refresh();
  }, [uuid]);

  const zips = listings.map(l => ({
    zip: l.zip,
    category: l.category ? l.category.name : 'n/a'
  }));
  const listing = listings[0];

  return (
    <Page.Content title='Business'>
      <UpdateListingCard listing={listing} onUpdateListing={updateListings} />
      <ListingCard
        loading={isLoading}
        listing={listing}
        zips={zips}
        />
      <Card title='Dev Test' isCollapsible isFullscreenable>
        <pre>
          <code>
            {JSON.stringify({business, listings}, null, 2)}
          </code>
        </pre>
      </Card>
    </Page.Content>
  );
}

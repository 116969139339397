
import React, { useCallback } from 'react';
import { withFormsy } from 'formsy-react';
import { Form } from 'tabler-react';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

const styles = (
  <style>
    {`
      .react-datepicker-wrapper, .react-datepicker__input-container {
        width: 100%;
      }
      .react-datepicker__time-list-item {
        font-size: .7rem;
      }
    `}
  </style>
);

function DateTimePicker({ getValue, setValue, getErrorMessage, label, required, ...props}) {
  const handleChange = useCallback((date) => {
    setValue(date);
  });

  return (
    <Form.Group label={label} isRequired={required}>
      { styles }
      <DatePicker
        customInput={<Form.Input />}
        showTimeSelect
        timeIntervals={15}
        timeFormat="h:mma"
        dateFormat="MMMM d, yyyy h:mm aa"
        selected={getValue()}
        onChange={handleChange}
      />
    </Form.Group>
  );
}

export default withFormsy(DateTimePicker);


import React, { useState, useContext, useCallback } from 'react';
import { AuthContext } from '../components/AuthContext.js';
import { Redirect } from 'react-router-dom';
import { Card, Form, Button, Alert } from 'tabler-react';
import { Link } from 'react-router-dom';

import StandaloneFormPage from '../components/StandaloneFormPage.js';
import useLoading from '../tools/useLoading.js';

const LoginPage = ({location}) => {
  const [user, account, login] = useContext(AuthContext);
  const [values, setValues] = useState({ username: '', password: '' });
  const [error, setError] = useState();
  const [redirect, setRedirect] = useState(false);
  const { isLoading, start, finish } = useLoading();

  const onSubmit = useCallback(e => {
    e.preventDefault();
    const { username, password } = values;

    setError(null);
    start();

    login(username, password).then(user => {
      finish();
      setRedirect(true);
    }).catch(error => {
      finish();
      setError(error && error.message || 'Login Unsucessful.');
      console.error(error);
    });
  }, [values, error, login]);

  const onChange = useCallback(e => {
    setValues({
      ...values,
      [e.currentTarget.name]: e.currentTarget.value
    });
  }, [values]);

  const { from } = location.state || { from: { pathname: '/' } }

  if (redirect === true) {
    return <Redirect to={from} />
  }

  return (
    <StandaloneFormPage imageURL={'/images/hnp-logo-150x.png'}>
      <Form className="card" onSubmit={onSubmit}>
        <Card.Body className="p-6">
          <Card.Title RootComponent="div">{'Sign In'}</Card.Title>
          <Form.Input
            name='username'
            label='Username'
            placeholder='Your Username / Email'
            onChange={onChange}
            value={values && values.username}
            />
          <Form.Input
            name='password'
            type='password'
            label='Password'
            placeholder='Enter Password...'
            onChange={onChange}
            value={values && values.password}
            />
          <Form.Footer>
            {error &&
              <Alert type="danger" icon="alert-triangle">
                {error}
              </Alert>
            }
            <Button type="submit" color="primary" loading={isLoading} disabled={isLoading} block={true}>
              {'Login!'}
            </Button>
            <Button RootComponent={Link} to='/login/forgot' color="default" disabled={isLoading} block={true}>
              {'Forgot Password'}
            </Button>
          </Form.Footer>
        </Card.Body>
      </Form>
    </StandaloneFormPage>
  );
}

export default LoginPage;

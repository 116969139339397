
import React from 'react';

function ripRouteProps(Component, locationsParams = [], matchParams = []) {
  return (props) => {
    const rippedProps = {};

    locationsParams.forEach(p => {
      try {
        if (props.location && props.location.state) {
          if (props.location.state[p]) {
            rippedProps[p] = props.location.state[p];
          }
        }
      } catch(e) {
        console.error('Rip Location State', e);
      }
    });

    matchParams.forEach(p => {
      try {
        if (props.match && props.match.params) {
          if (props.match.params[p]) {
            rippedProps[p] = props.match.params[p];
          }
        }
      } catch(e) {
        console.error('Rip Match Params', e);
      }
    });

    return (
      <Component {...rippedProps} {...props} />
    );
  }
}

export default ripRouteProps;


export function unflatten(data) {
  "use strict";

  if (Object(data) !== data || Array.isArray(data)) {
      return data;
  }

  let result = {}, cur, prop, idx, last, temp;

  for (var p in data) {
    let cur = result;
    let prop = "";
    let last = 0;

    do {
      idx = p.indexOf(".", last);
      temp = p.substring(last, idx !== -1 ? idx : undefined);
      cur = cur[prop] || (cur[prop] = (!isNaN(parseInt(temp)) ? [] : {}));
      prop = temp;
      last = idx + 1;
    } while(idx >= 0);

    cur[prop] = data[p];
  }

  return result[""];
}

export function flatten(data, flattenArray, includeEmpty) {
  const result = {};

  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
        result[prop] = cur;
    } else if (Array.isArray(cur)) {
      if (flattenArray) {
        for (let i = 0, l = cur.length; i < l; i++) {
          recurse(cur[i], prop ? prop + '.' + i : '' + i);
          if (l == 0)
            result[prop] = [];
        }
      } else {
        result[prop] = [...cur];
      }
    } else {
      let isEmpty = true;

      for (let p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + '.' + p : p);
      }

      if (isEmpty && includeEmpty)
        result[prop] = {};
    }
  }

  recurse(data, '');
  return result;
}

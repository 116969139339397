
import React from 'react';
import { Page } from 'tabler-react';
import CategoryAvailabilitySearch from '../components/CategoryAvailabilitySearch.js';

const AvailabilityPage = () => (
  <Page.Content title='Zip Availability'>
    <CategoryAvailabilitySearch />
  </Page.Content>
);

export default AvailabilityPage;

import React, { useState, useEffect } from 'react';
import { Page } from 'tabler-react';
import TablerTable from '../components/Table/TablerTable.js';
import { getOwnTransactions } from '../API';
import moment from 'moment';

function toDateCell(format = 'MMM Do, YYYY', fallback = '') {
  return ({ cell: { value } }) => 
    value ? moment(value).format(format) : fallback;
}

function toUSDCell() {
  return ({ cell: { value } }) => '$' + (value / 100).toFixed(2);
}

const columns = [{
  accessor: 'ref_name',
  Header: 'Ref'
}, {
  accessor: 'amount',
  Header: 'Amount',
  Cell: toUSDCell()
}, {
  accessor: 'code',
  Header: 'Code',
}, {
  accessor: 'type',
  Header: 'Type'
}, {
  accessor: 'created_at',
  Header: 'Posted',
  Cell: toDateCell()
}, {
  accessor: 'resolved_at',
  Header: 'Resolved',
  Cell: toDateCell(undefined, '---')
}];

const TransactionsPage = () => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    getOwnTransactions().then(({ transactions }) => {
      setTransactions(transactions);
    });
  }, []);

  return (
    <Page.Content title='Transactions'>
      <TablerTable
        title='Transactions'
        data={transactions}
        columns={columns}
        defaultPageSize={10}
        />
    </Page.Content>
  );
}

export default TransactionsPage;

import Frisbee from 'frisbee';

const FORCE_LIVE_MODE = true;
export const DEBUG_MODE = false;

export const LOCAL_MODE = !FORCE_LIVE_MODE && (
  window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1'
);

export const API_DEV_URL = LOCAL_MODE ?
  'http://127.0.0.1:5000' :
  'https://hnp-api-dev.herokuapp.com';

export const API_URL = DEBUG_MODE || LOCAL_MODE ?
  API_DEV_URL : 'https://hnp-api.herokuapp.com';

export const api = new Frisbee({
  baseURI: API_URL,
  mode: 'cors',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

export const login = (username, password) => api
  .post('/v2/users/login', {
    body: { username, password }
  }).then(r => r.body);

export const loginRequestReset = (email) => api
  .post('/v3/login/reset/request', {
    body: { email }
  }).then(r => r.body);

export const loginReset = (email, code, password) => api
  .post('/v3/login/reset', {
    body: { email, code, password }
  }).then(r => r.body);

export const getS3UploadUrl = (name, type, size) => api
  .get('/v3/files/sign-s3', {
    body: { name, type, size }
  }).then(r => r.body);

export const getFile = (uuid) => api
  .get(`/v3/files/${uuid}`)
  .then(r => r.body);

export const getUserAccount = () => api
  .get('/v3/users/account')
  .then(r => r.body);

export const getFeedback = (all) => (
  all ?
    api.get('/v3/listings/feedback') :
    api.get('/v3/me/feedback')
  ).then(r => r.body);

export const getSalesTeam = () => api
  .get('/v3/accounts/salesTeam')
  .then(r => r.body);

export const addSalesTeamMember = (member) => api
  .post('/v3/accounts/salesTeam', {
    body: member
  }).then(r => r.body);

export const getProxies = () => api
  .get('/v3/test/proxies')
  .then(r => r.body);

export const getOwnEvents = (q) => api
  .get('/v3/me/events')
  .then(r => r.body);

export const searchEvents = (q) => api
  .get('/v3/events', { body: { q }})
  .then(r => r.body);

export const addEvent = (event) => api
  .post('/v3/events', { body: { event }})
  .then(r => r.body);

export const deleteEvent = (uuid) => api
  .del(`/v3/events/${uuid}`)
  .then(r => r.body);

export const searchBusinesses = (q) => api
  .get('/v3/businesses', { body: { q }})
  .then(r => r.body);

export const getBusinessWithListings = (uuid) => api
  .get(`/v3/businesses/${uuid}`)
  .then(r => r.body);

export const updateListingsForAccount = (account_uuid, body) => api
  .put(`/v3/listings/${account_uuid}`, { body })
  .then(r => r.body);

export const getOwnTransactions = () => api
  .get(`/v3/me/transactions`)
  .then(r => r.body);

export const getOwnListings = () => api
  .get(`/v3/me/listings`)
  .then(r => r.body);

export const updateOwnListing = (body) => api
  .put(`/v3/me/listing`, { body })
  .then(r => r.body);

export const getNotes = (admin) => api
  .get(admin ? '/v3/notes' : '/v3/me/notes')
  .then(r => r.body);

export const createNote = (note) => api
  .post('/v3/notes', { body: { note }})
  .then(r => r.body);

export const getZoomMeetings = () => api
  .get('/v3/zoom/meetings')
  .then(r => r.body);

export const getZoomMeetingPhoneNumbers = (meeting_id) => api
  .get(`/v3/zoom/phones/${meeting_id}`)
  .then(r => r.body);

export const smsZoomRegistrants = (meeting_id, message) => api
  .post(`/v3/zoom/sms/${meeting_id}`, { body: { message } })
  .then(r => r.body);

export const getVolunteerism = () => api
  .get('/v3/volunteerism')
  .then(r => r.body);

export const addVolunteerHour = (hour) => api
  .post('/v3/volunteerism/hours', { body: { hour }})
  .then(r => r.body);

export const updateVolunteerHour = (hour) => api
  .put(`/v3/volunteerism/hours/${hour.uuid}`, { body: hour })
  .then(r => r.body);

export const deleteVolunteerHour = (hour) => api
  .del(`/v3/volunteerism/hours/${hour.uuid}`)
  .then(r => r.body);

export const addVolunteerOrganization = (organization) => api
  .post('/v3/volunteerism/organizations', { body: { organization }})
  .then(r => r.body);

export const updateVolunteerOrganization= (organization) => api
  .put(`/v3/volunteerism/organizations/${organization.uuid}`, { body: organization })
  .then(r => r.body);

export const deleteVolunteerOrganization = (organization) => api
  .del(`/v3/volunteerism/organizations/${organization.uuid}`)
  .then(r => r.body);

export const getListingsByZip = (zip) => api
  .get(`/v3/listings/z/${zip}`)
  .then(r => r.body);

export const addCategory = (category) => api
  .post('/v3/categories', { body: { category }})
  .then(r => r.body);

export const getCategories = () => api
  .get('/v3/categories')
  .then(r => r.body);

export const getCategory = (uuid) => api
  .get(`/v3/categories/${uuid}`)
  .then(r => r.body);

export const getListingsInCategoryByZip = (uuid) => api
  .get(`/v3/categories/${uuid}/listingsByZip`)
  .then(r => r.body);

export const updateCategory = (category) => api
  .put(`/v3/categories/${category.uuid}`, { body: category })
  .then(r => r.body);

export const checkCategoryAvailability = (category_uuid, zip) => api
  .get(`/v2/listings/categories/${category_uuid}/${zip}`)
  .then(r => r.body);

export const checkPricing = (coupon, zips = []) => api
  .get(`/v2/pricing/business`, {
    body: {
      coupon,
      zips: [].concat(zips).join()
    }
  }).then(r => r.body);

export const createBusinessWithListings = (
  { zips, ...business }, stripeToken, coupon
) => api.post('/v3/businesses/full', {
    body: {
      business, zips, stripeToken, coupon
    }
  }).then(r => r.body);

export const createBusinessWithListingsFree = ({ zips, ...business }) =>
  api.post('/v2/businesses/free', {
    body: { business, zips }
  }).then(r => r.body);

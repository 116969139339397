
import React from 'react';
import { Page } from 'tabler-react';
import ZoomPhoneNumbers from '../components/ZoomPhoneNumbers.js';

export default function ZoomInfoPage() {

  return (
    <Page.Content title='Zoom Info'>
      <ZoomPhoneNumbers />
    </Page.Content>
  );
}

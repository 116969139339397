
import React, { useState, useCallback, useEffect, useRef, useContext } from 'react';
import { Page, Form, Button } from 'tabler-react';
import moment from 'moment';
import { debounce, throttle } from 'throttle-debounce';
import TablerTable from '../components/Table/TablerTable.js';
import { Link } from 'react-router-dom';

import { searchBusinesses } from '../API.js';
import { addNote } from '../components/Dialog/AddNote.js';
import { AuthContext } from '../components/AuthContext.js';
import useLoading from '../tools/useLoading.js';

const columns = [{
  id: 'info',
  Cell: ({ cell: { row } }) => (
    <Button.List align='center'>
      {Boolean(row.original.user && row.original.user.username) ? (
        <Button color='success' size='sm' pill disabled>{'Has User'}</Button>
      ) : (
        <Button color='danger' size='sm' pill>{'No User'}</Button>
      )}
      <Button color='primary' size='sm' onClick={() => addNote(row.original.uuid, row.original.company)}>
        Add Note
      </Button>
    </Button.List>
  )
}, {
  id: 'company',
  Header: 'Company',
  accessor: 'company',
  Cell: ({ cell: { value, row } }) => (
    <Link to={{
        pathname: `/businesses/${row.original.uuid}`,
        state: { business: row.original }
      }}>
      {value}
    </Link>
  )
}, {
  id: 'name',
  Header: 'Owner',
  accessor: m => m.name ? m.name.first + ' ' + m.name.last : ''
}, {
  id: 'ref',
  Header: 'Referrer',
  accessor: m => m.ref && m.ref.name ? m.ref.name.first + ' ' + m.ref.name.last : ''
}, {
  id: 'email',
  Header: 'Email',
  accessor: m => m.email || '(none on file)'
}, {
  id: 'listings',
  Header: 'Listings',
  accessor: ({listings = []}) => listings.length,
  Cell: ({ cell: {value: count, row} }) => {
    if (count) {
      const { listings } = row.original;
      return `${listings.length} [${listings.map(l => l.zip).join(', ')}]`;
    }

    return '';
  }
}, {
  id: 'created',
  Header: 'Joined',
  accessor: 'createdAt',
  Cell: ({ cell: {value} }) => moment(value).format('MMM Do, YYYY')
}];

const BusinessesPage = () => {
  const [user] = useContext(AuthContext);
  const [query, setQuery] = useState('');
  const [businesses, setBusinesses] = useState([]);
  const { isLoading, start, finish } = useLoading();

  const search = useCallback(query => {
    start();

    searchBusinesses(query, user.role === 'admin').then(({businesses}) => {
      setBusinesses(businesses);
      finish();
    }).catch(finish);
  }, [start, finish, user]);

  const searchDebounced = useRef(throttle(1000, false, search), [debounce, search]);

  useEffect(() => search(query), []);

  const onChangeSearch = useCallback(value => {
    setQuery(query => {
      if (value !== query && searchDebounced.current) {
        searchDebounced.current(value);
      }

      return value;
    });
  }, [searchDebounced]);

  return (
    <Page.Content title='Businesses'>
      <TablerTable
        title='Business List'
        loading={isLoading}
        query={query}
        onChangeSearch={onChangeSearch}
        searchPlaceholder='Search by Name or Email...'
        data={businesses}
        columns={columns}
        defaultPageSize={10}
        />
    </Page.Content>
  );
}

export default BusinessesPage;
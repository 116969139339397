
import React, { useState, useEffect, useMemo } from 'react';
import { Page } from 'tabler-react';

import TablerTable from '../components/Table/TablerTable.js';
import { gql, useQuery } from '@apollo/client';
import { CSVLink } from "react-csv";
import { format } from 'date-fns'

const query = gql`
  {
    ghs_registrants {
      first_name
      last_name
      heard_from
      referred_by
      create_time
    }
  }
`;

const columns = [{
  Header: 'First Name',
  accessor: 'first_name'
}, {
  Header: 'Last Name',
  accessor: 'last_name'
}, {
//   Header: 'Email',
//   accessor: 'email'
// }, {
  Header: 'Heard From',
  accessor: 'heard_from'
}, {
  Header: 'Referrered By',
  accessor: 'referred_by'
}, {
  Header: 'Registered',
  accessor: 'create_time',
  Cell: ({ value }) => format(new Date(value), 'MMMM do @ ha'),
  // Cell: (v) => Object.keys(v).join(','),
  // Cell: ({ value }) => String(value)
}];

export default function GhsRegistrantsPage() {
  const { loading, error, data } = useQuery(query);

  return (
    <Page.Content title='GHS 2020 Registrants'>
      {
        loading ? <p>Loading...</p> : null
      }
      <TablerTable
        title={`Registrants for the Summit`}
        data={data ? data.ghs_registrants : []}
        columns={columns}
        defaultPageSize={10}
        />
    </Page.Content>
  );
}

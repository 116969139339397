
import React, { useContext } from 'react';
import { AuthContext } from './AuthContext.js';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, validate, redirectTo, noRedirect, ...rest }) => {
  const [user, account] = useContext(AuthContext);
  const authorized = Boolean(user && user.uuid);
  const valid = !validate || Boolean(validate(user || {}, account || {}));

  return (
    <Route {...rest} render={(props) => {
      if (authorized && valid) {
        return (
        <Component 
          user={user}
          account={account}
          {...props}
          />
        );
      } else if (noRedirect || !redirectTo) {
        return null;
      }

      return (
        <Redirect to={{
          pathname: authorized ? redirectTo || '/login' : '/login',
          state: { from: props.location }
        }} />
      );
    }} />
  );
}

const AdminRoute = (props) => (
  <ProtectedRoute
    validate={(user, account) => user.role === 'admin'}
    redirectTo='/'
    exact
    {...props}
    />
);

const SalesRoute = (props) => (
  <ProtectedRoute
    validate={(user, account) => user.role === 'admin' ||  user.role === 'manager' ||  user.role === 'sales'}
    redirectTo='/'
    exact
    {...props}
    />
);

const AccountManagerRoute = (props) => (
  <ProtectedRoute
    validate={(user, account) => user.role === 'admin' ||  user.role === 'manager'}
    redirectTo='/'
    exact
    {...props}
    />
);

const BusinessRoute = (props) => (
  <ProtectedRoute
    validate={(user, account) => account && account.type === 'business'}
    {...props}
    />
);

const LoginRequired = (props) => (
  <ProtectedRoute 
    validate={user => user && user.uuid} redirectTo='/login' 
    component={() => null}
    {...props}
    />
);

export default ProtectedRoute;
export {
  AdminRoute,
  SalesRoute,
  BusinessRoute,
  AccountManagerRoute,
  LoginRequired
};


import React, { useState, useEffect } from 'react';
import { Page } from 'tabler-react';

import { getProxies } from '../API.js';
import TablerTable from '../components/Table/TablerTable.js';

const columns = [{
  Header: 'Host',
  accessor: 'host'
}, {
  Header: 'Port',
  accessor: 'port'
}, {
  Header: 'Username',
  accessor: 'username'
}, {
  Header: 'Password',
  accessor: 'password'
}];

export default function ProxiesPage() {
  const [proxies, setProxies] = useState([]);

  useEffect(() => {
    getProxies().then(({ proxies }) => {
      setProxies(proxies);
    });
  }, []);

  return (
    <Page.Content title='Instagram Proxies'>
      <TablerTable
        title='Proxies'
        data={proxies}
        columns={columns}
        defaultPageSize={10}
        />
    </Page.Content>
  );
}

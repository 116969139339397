
import React, { useState, useEffect, useContext } from 'react';
import { Page } from 'tabler-react';
import moment from 'moment';

import { getNotes } from '../API.js';
import TablerTable from '../components/Table/TablerTable.js';
import { AuthContext } from '../components/AuthContext.js';

const columns = [{
  id: 'account',
  Header: 'Account',
  accessor: 'account.company'
}, {
  id: 'message',
  Header: 'Message',
  accessor: 'message',
},{
  id: 'created',
  Header: 'Created',
  accessor: 'createdAt',
  Cell: ({cell: {value}}) => moment(value).format('MMM Do, YYYY')
}];

const NotesPage = () => {
  const [notes, setNotes] = useState([]);
  const [user] = useContext(AuthContext);

  useEffect(() => {
    getNotes(user.role === 'admin' || user.role === 'manager').then(({notes}) => {
      setNotes(notes);
    });
  }, [])

  return (
    <Page.Content title='Notes'>
      <TablerTable
        title='Notes'
        data={notes}
        columns={columns}
        defaultPageSize={10}
        />
    </Page.Content>
  );
}

export default NotesPage;
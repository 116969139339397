
import React from 'react';

const styles = {
  container: {
    position: 'fixed',
    zIndex: 1000,
    pointerEvents: 'none',
    backgroundColor: 'yellow',
    opacity: .5,
    bottom: 20,
    right: 20,
    padding: 10,
    borderRadius: 5
  },
  text: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'black'
  }
};

const DebugMode = ({enabled = true, message}) => enabled ? (
  <div style={styles.container}>
    <span style={styles.text}>
      { message || 'Test Mode' }
    </span>
  </div>
) : null;

export default DebugMode;

import React from 'react';
import { Page } from 'tabler-react';

const AddBusinessSuccessPage = () => (
  <Page.Content title='Succesfully Added Business'>
    <div>
      <p>Added business successfully!</p>
    </div>
  </Page.Content>
);

export default AddBusinessSuccessPage;

import React, { useState, useCallback } from 'react';
import { Card, Form, Button, Alert, Dimmer } from 'tabler-react';
import { Link, useParams } from 'react-router-dom';

import StandaloneFormPage from '../components/StandaloneFormPage.js';
import useLoading from '../tools/useLoading.js';
import { loginReset } from '../API.js';

const LoginResetPage = ({location}) => {
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState();
  const { isLoading, start, finish } = useLoading();
  const { code, email64 } = useParams();
  const email = atob(email64);

  const onSubmit = useCallback(e => {
    e.preventDefault();
    setError(null);
    
    if (password && password.length > 7) {
      if (password !== confirm) {
        return setError('Passwords do not match.');
      }

      start();

      loginReset(email, code, password).then(({success}) => {
        setSent(success);
        finish();
      }).catch(error => {
        finish();
        setError(error && error.message || 'An error occurred.');
        console.error(error);
      });
    } else {
      setError('Invalid Password, Password must be at least 8 characters.');
    }
  }, [password, confirm]);

  return (
    <StandaloneFormPage imageURL={'/images/hnp-logo-150x.png'}>
      <Form className="card" onSubmit={onSubmit}>
        <Card.Body className="p-6">
          <Card.Title RootComponent="div">{'Reset Password'}</Card.Title>
          { !sent && 
            <Dimmer active={isLoading} loader={isLoading}>
              <h4 style={{textAlign: 'center'}}>{email}</h4>
              <Form.Input
                name='password'
                label='Password'
                type='password'
                placeholder='New Password'
                onChange={e => setPassword(e.currentTarget.value)}
                value={password}
                />
              <Form.Input
                name='confirm'
                label='Confirm Password'
                type='password'
                placeholder='Confirm Password'
                onChange={e => setConfirm(e.currentTarget.value)}
                value={confirm}
                />
              <Form.Footer>
                {error &&
                  <Alert type="danger" icon="alert-triangle">
                    {error}
                  </Alert>
                }
                <Button type="submit" color="primary" isLoading={isLoading} disabled={isLoading} block={true}>
                  {'Reset Password!'}
                </Button>
                <Button RootComponent={Link} to='/login' color="default" disabled={isLoading} block={true}>
                  {'I remember my password.'}
                </Button>
              </Form.Footer>
            </Dimmer>
          }
          { sent &&
            <>
              <p>Password successfully updated.</p>
              <p><Link to='/login'>Please login</Link>.</p>
            </>
          }
        </Card.Body>
      </Form>
    </StandaloneFormPage>
  );
}

export default LoginResetPage;


function fallbackCopyToClipboard(text) {
  let success = false;
  const textArea = document.createElement("textarea");

  textArea.value = text;
  textArea.style.position="fixed";  //avoid scrolling to bottom
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    success = document.execCommand('copy');
    console.warn(`[Fallback] ${success ? 'Succeeded' : 'Failed'} Copying Text`);
  } catch (error) {
    success = false;
    console.error('[Fallback] Unable to Copy Text', error);
  }

  document.body.removeChild(textArea);

  return success;
}

export default async function copyToClipboard(text) {
  if (!navigator.clipboard) {
    return fallbackCopyToClipboard(text);
  }

  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    console.error('Unable to Copy Text', error);
  }
}
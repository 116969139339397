
import React, { useContext } from 'react';
import { AuthContext } from '../components/AuthContext.js';

export default function ProtectedContent({validate, children}) {
  const [user, account] = useContext(AuthContext);
  const authorized = Boolean(user) && user.uuid;
  const valid = !validate || Boolean(validate(user || {}, account || {}));

  if (authorized && valid) {
    return children;
  }

  return null;
}

export const LoggedOutContent = ({children}) => {
  const [user] = useContext(AuthContext);
  const authorized = Boolean(user) && user.uuid;

  if (!authorized) {
    return children;
  }

  return null;
}

export const withProtection = (Component, params = {}) => {
  const { override } = params;
  return (props) => (
    <ProtectedContent {...params}>
      <Component {...props} {...override} />
    </ProtectedContent>
  );
}


import React, { useState, useEffect, useCallback } from 'react';
import { Page, Grid } from 'tabler-react';
import moment from 'moment';
import TablerTable from '../components/Table/TablerTable.js';
import UpdateEventCard from '../components/UpdateEventCard.js';

import { getOwnEvents, addEvent } from '../API.js';

const columns = [{
  id: 'title',
  Header: 'name',
  accessor: 'title'
},{
  id: 'starts',
  Header: 'Start',
  accessor: 'starts_at',
  Cell: ({ cell: { value } }) => moment(value).format('MMM Do, YYYY @ h:mma')
}];

export default () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getOwnEvents().then(({events}) => {
      setEvents(events);
      setLoading(false);
    }).catch(() => setLoading(false));
  }, []);

  const onUpdateEvent = useCallback((event, start, finish) => {
    start();
    addEvent(event).then(({ event }) => {
      setEvents(events => [...events, event]); // use redux
      finish(true);
    }).catch(finish);
  }, []);

  return (
    <Page.Content title='Events'>
      <Grid>
        <Grid.Row>
          <Grid.Col xl={8} lg={6} md={6} sm={12} xs={12}>
            <TablerTable
              title='Event List'
              loading={loading}
              data={events}
              columns={columns}
              defaultPageSize={10}
              />
          </Grid.Col>
          <Grid.Col xl={4} lg={6} md={6} sm={12} xs={12}>
            <UpdateEventCard onUpdateEvent={onUpdateEvent} />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Page.Content>
  );
}


import React, { useMemo, useState, useEffect } from 'react';
import { Dimmer, Button, ContactCard } from 'tabler-react';
import Modal from 'react-responsive-modal';

const EMAIL_SUBJECT = 'Hello to a fellow Happy Neighborhood Project member!';

function ListingCardModal({ listing, onClose }) {
  const { name, logo, address, zip, website, phone, email, description, category, account } = listing || {};
  const data = {
    cardTitle: `${category ? category.name : 'Company'} in ${zip}`,
    details: []
  };

  if (name) {
    data.name = name;
  }

  if (listing && logo && logo.url) {
    data.objectURL = logo.url;
    data.alt = name ? `Logo for '${name}'` : 'Company Logo';
  }

  if (address) {
    const { street, city, state, zip } = address;

    data.address = {
      line1: street,
      line2: `${city}, ${state} ${zip}`
    }
  }

  if (account && account.name) {
    data.details.push({
      title: 'Contact Name',
      content: `${account.name.first} ${account.name.last}`
    });
  }

  if (phone) {
    data.details.push({
      title: 'Phone Number',
      content: (
        <a href={`tel:${phone}`}>
          { phone.slice(-10).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') }
        </a>
      )
    });
  }

  if (email) {
    data.details.push({
      title: 'Email Address',
      content: (
        <a href={`mailto:${email}?subject=${EMAIL_SUBJECT}`}>
          { email }
        </a>
      )
    });
  }

  if (website) {
    data.details.push({
      title: 'Website',
      content: (
        <a href={website} target='_blank' rel='nofollow noreferrer'>
          { website }
        </a>
      )
    });
  }

  if (description) {
    data.description = description;
  }

  return (
    <Modal open={Boolean(listing)} onClose={onClose} center showCloseIcon={false}>
      <style>
        {`
          .card.otg {
            min-width: 500px;
          }
          .media {
            align-items: end;
          }
          .media-object img { 
            max-height: 128px;
            max-width: 128x;
          }
        `}
      </style>
      <ContactCard className='otg' {...data} />
    </Modal>
  );
}

export default ListingCardModal;
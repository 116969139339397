
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { SkyLightStateless } from 'react-skylight';
import { confirmable, createConfirmation } from 'react-confirm';
import Formsy from 'formsy-react';
import { Card, Grid, Button } from 'tabler-react';
import { Input } from '../Form';

import { updateCategory } from '../../API.js';
import useLoading from '../../tools/useLoading.js';

// TODO: EDIT ALL THIS
export const EditCategory = confirmable(({ category, show, proceed, dismiss }) => {
  const { loading, start, finish } = useLoading();
  const form = useRef();
  const [valid, setValid] = useState(false);

  useEffect(() => {
    form && form.current && form.current.reset(category);
  }, [category]);

  const onSubmit = useCallback(async (data, reset) => {
    try {
      start();
      const { updated } = await updateCategory(data);
      reset();
      finish();

      if (updated) {
        proceed(data);
      }
    } catch(error) {
      finish();
    }
  }, [category]);

  return (
    <SkyLightStateless
      onCloseClicked={dismiss}
      onOverlayClicked={dismiss}
      isVisible={show}
      dialogStyles={{minHeight: 10}}
      closeButtonStyle={{display: 'none'}}
      >
      <style>{'.mb0 { margin-bottom: 0; }'}</style>
      <Card className='mb0'>
        <Card.Header>
          <Card.Title>Edit Category</Card.Title>
        </Card.Header>
        <Card.Body>
          <Formsy
            ref={ref => form.current = ref}
            onValidSubmit={onSubmit}
            onValid={() => setValid(true)}
            onInvalid={() => setValid(false)}
            >
            <Input type='hidden' name='uuid' value={category.uuid} />
            <Grid.Row>
              <Grid.Col xl={6} lg={6} md={6} sm={12} xs={12} width={12}>
                <Input name='name' label='Category Name' placeholder='Category Name' required />
              </Grid.Col>
              <Grid.Col xl={6} lg={6} md={6} sm={12} xs={12} width={12}>
                <Input name='slug' label='URL Slug' placeholder='category-name' />
              </Grid.Col>
            </Grid.Row>
            <Button.List>
              <Button type='submit' color='success' disabled={loading || !valid}>Update</Button>
              <Button color='danger' disabled={loading} onClick={dismiss}>Cancel</Button>
            </Button.List>
          </Formsy>
        </Card.Body>
      </Card>
    </SkyLightStateless>
  );
});

const showEditCategory = createConfirmation(EditCategory);
export const editCategory = (category) => {
  return showEditCategory({ category });
}


import React, { useState, useCallback, useEffect } from 'react';
import { Card, Button, Icon, Form, Grid, Dimmer } from 'tabler-react';
import dottie from 'dottie';
import STATES_USA from '../../tools/misc/StatesUSA.js';

const DEFAULT = {
  address: { }
};

const VolunteerOrganizationForm = ({ organization = DEFAULT, loading, onSave, onClose }) => {
  const [data, setData] = useState(organization);
  const isEditing = Boolean(organization && organization.uuid);
  const isValid = Boolean(data.company && data.name);

  useEffect(() => {
    setData({ ...DEFAULT, ...organization });
  }, [organization]);

  const setForm = useCallback(key => e => {
    const value = e.currentTarget.value;
    setData(data => {
      const update = Object.assign({}, data);
      dottie.set(update, key, value);

      return update;
    });
  }, []);

  return (
    <Card>
      <Card.Header>
        <Card.Title>{ isEditing ? 'Edit' : 'Add' } Organization</Card.Title>
        <Card.Options>
          { Boolean(onClose) && 
            <Button onClick={onClose} disabled={loading} color="warning" size="sm" className="ml-2">
              <Icon name='x' />
            </Button>
          }
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <Dimmer active={loading} loader={loading}>
          <Form.Input label='Name' value={dottie.get(data, 'company')} onChange={setForm('company')} />
          <Form.Input label='Contact' value={dottie.get(data, 'name')} onChange={setForm('name')} />
          <Form.Input type='phone' label='Phone' value={dottie.get(data, 'phone')} onChange={setForm('phone')} />
          <hr />
          <Form.Input label='Address' value={dottie.get(data, 'address.street')} onChange={setForm('address.street')} />
          <Grid.Row>
            <Grid.Col>
              <Form.Input label='City' value={dottie.get(data, 'address.city')} onChange={setForm('address.city')} />
            </Grid.Col>
            <Grid.Col>
              <Form.Select label='State' value={dottie.get(data, 'address.state')} onChange={setForm('address.state')}>
                <option disabled selected default value=''></option>
                { STATES_USA.map(state => (
                  <option key={state.name} value={state.name}>{ state.name }</option>
                )) }
              </Form.Select>
            </Grid.Col>
            <Grid.Col>
              <Form.Input label='Zip' value={dottie.get(data, 'address.zip')} onChange={setForm('address.zip')} />
            </Grid.Col>
          </Grid.Row>
        </Dimmer>
      </Card.Body>
      <Card.Footer>
        <Button.List>
          <Button color='primary' onClick={() => onSave(data)} disabled={!isValid || loading}>{ isEditing ? 'Save' : 'Add' }</Button>
          <Button color='warning' onClick={onClose} disabled={loading}>Cancel</Button>
        </Button.List>
      </Card.Footer>
    </Card>
  );
}

export default VolunteerOrganizationForm;
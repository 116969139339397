
import React, { useState, useEffect, useCallback } from 'react';
import { Page, Tabs, Tab, Card, Button, Alert } from 'tabler-react';
import { getVolunteerism, addVolunteerHour, updateVolunteerHour, deleteVolunteerHour, 
  addVolunteerOrganization, updateVolunteerOrganization, deleteVolunteerOrganization } from '../API.js';
import VolunteerHourList from '../components/Volunteerism/VolunteerHourList.js';
import VolunteerOrganizationList from '../components/Volunteerism/VolunteerOrganizationList.js';
import VolunteerHourForm from '../components/Volunteerism/VolunteerHourForm.js';
import VolunteerOrganizationForm from '../components/Volunteerism/VolunteerOrganizationForm.js';
import { SkyLightStateless } from 'react-skylight';

function updateArrayItem(array, item, matchParam = 'uuid') {
  try {
    return array.map(_item => {
      if (item[matchParam] === _item[matchParam]) {
        return { ..._item, ...item };
      }
  
      return _item;
    });
  } catch (e) {
    console.error('updateArrayItem', e);
    return Array.concat(array);
  }
}

function removeFromArray(array, item, matchParam = 'uuid') {
  return array.filter(_item => {
    return item[matchParam] !== _item[matchParam]
  });
}

const VolunteerismPage = () => {
  const [organizations, setOrganizations] = useState([]);
  const [hours, setHours] = useState([]);
  const [hourEditing, setHourEditing] = useState();
  const [organizationEditing, setOrganizationEditing] = useState();
  const [loading, setLoading] = useState();

  const organizationsNeeded = !organizations.length && loading !== 'organization';

  useEffect(() => {
    (async () => {
      setLoading('organization');
      const { hours, organizations } = await getVolunteerism();
      setHours(hours);
      setOrganizations(organizations);
      setLoading();
    })();
  }, []);

  const saveHours = useCallback(async data => {
    setLoading('hour');
    if (data.uuid) {
      const { hour } = await updateVolunteerHour(data);
      setHours(hours => updateArrayItem(hours, hour));
    } else {
      const { hour } = await addVolunteerHour(data);
      setHours(hours => [hour, ...hours]);
    }

    setLoading();
    setHourEditing();
  }, []);

  const deleteHour = useCallback(async hour => {
    setLoading('hour');
    const { success } = await deleteVolunteerHour(hour);
    setLoading();

    if (success) {
      setHours(hours => removeFromArray(hours, hour));
    }
  });

  const saveOrganization = useCallback(async data => {
    setLoading('organization');
    if (data.uuid) {
      const { organization } = await updateVolunteerOrganization(data);
      setOrganizations(organizations => updateArrayItem(organizations, organization));
    } else {
      const { organization } = await addVolunteerOrganization(data);
      setOrganizations(organizations => [organization, ...organizations]);
    }

    setLoading();
    setOrganizationEditing();
  }, []);

  const deleteOrganization = useCallback(async organization => {
    setLoading('organization');
    const { success } = await deleteVolunteerOrganization(organization);
    setLoading();

    if (success) {
      setOrganizations(organizations => removeFromArray(organizations, organization));
    }
  });

  return (
    <Page.Content title='Volunteerism'>
      <Card>
        <Card.Header>
          <Card.Title></Card.Title>
          <Card.Options>
            <Button color='primary' icon='clock' disabled={organizationsNeeded} onClick={() => setHourEditing({})}>Add Hours</Button>
            <Button color='primary' icon='plus' onClick={() => setOrganizationEditing({})} className='ml-2'>Add Organization</Button>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          { organizationsNeeded &&
            <Alert type='warning' icon='alert-triangle'>
              <p>In order to add volunteer hours, you must first create an organization.</p>
              <Button size='sm' onClick={() => setOrganizationEditing({})}>Add Organization</Button>
            </Alert>
          }
          <Tabs initialTab='Hours'>
            <Tab title='Hours'>
              <VolunteerHourList 
                hours={hours} 
                organizations={organizations} 
                loading={loading === 'hour'}
                onEdit={setHourEditing}
                onDelete={deleteHour}
                />
            </Tab>
            <Tab title='Organizations'>
              <VolunteerOrganizationList 
                organizations={organizations} 
                loading={loading === 'organization'}
                onEdit={setOrganizationEditing}
                onDelete={deleteOrganization}
                />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
      <SkyLightStateless
        onOverlayClicked={() => setHourEditing()}
        isVisible={Boolean(hourEditing)}
        closeButtonStyle={{display: 'none'}}
        >
        <VolunteerHourForm 
          organizations={organizations} 
          onClose={() => setHourEditing()} 
          onSave={saveHours}
          hour={hourEditing}
          loading={loading === 'hour'}
          />
      </SkyLightStateless>
      <SkyLightStateless
        onOverlayClicked={() => setOrganizationEditing()}
        isVisible={Boolean(organizationEditing)}
        dialogStyles={{ marginTop: '-40%' }}
        closeButtonStyle={{display: 'none'}}
        >
        <VolunteerOrganizationForm 
          loading={loading === 'organization'}
          organization={organizationEditing}
          onClose={() => setOrganizationEditing()} 
          onSave={saveOrganization}
          />
      </SkyLightStateless>
    </Page.Content>
  );
}

export default VolunteerismPage;

import React from 'react';

const NEWLINE_REGEX = /(\r\n|\r|\n)/g;

const nl2br = (input) => {
  if (typeof input !== 'string') {
    return input;
  }

  return input.split(NEWLINE_REGEX).map((line, i) => 
    line.match(NEWLINE_REGEX) ? <br key={i} /> : line
  );
}

export default nl2br;

import React, { useContext } from 'react';
import { Page, Card } from 'tabler-react';
import { AuthContext } from '../components/AuthContext.js';
import ProtectedContent from '../components/ProtectedContent.js';
import SignupLinkView from '../components/SignupLinkView.js';

export default function TestPage() {
  const [user, account] = useContext(AuthContext);

  return (
    <Page.Content title='Test Info'>
      <ProtectedContent>
        <Card>
          <Card.Body>
            <h3>This shows only if you're logged in!</h3>
            <ProtectedContent validate={(user, account) => account.type === 'account-manager'}>
              <h3>This shows only for an account manager!</h3>
              <SignupLinkView />
            </ProtectedContent>
          </Card.Body>
        </Card>
      </ProtectedContent>
      <Card>
        <Card.Header>
          <h2>User Object</h2>
        </Card.Header>
        <Card.Body>
        <pre>{JSON.stringify(user, null, 2)}</pre>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <h2>Account Object</h2>
        </Card.Header>
        <Card.Body>
          <pre>{JSON.stringify(account, null, 2)}</pre>
        </Card.Body>
      </Card>
    </Page.Content>
  );
}


import React, { useState, useCallback } from 'react';
import {
  CardNumberElement, CardExpiryElement, CardCvcElement, Elements, injectStripe
} from 'react-stripe-elements';
import { Dimmer, Card, Grid, Form, Alert, Button } from 'tabler-react';
import useLoading from '../tools/useLoading.js';

const StripeCreditCardElement = ({stripeData, onToken, stripe, ...props}) => {
  const [token, setToken] = useState();
  const [name, setName] = useState('');
  const { isLoading, start, finish } = useLoading();

  const submit = useCallback(async (e) => {
    e.preventDefault();
    start();

    const { token } = await stripe.createToken({name, ...stripeData});

    setToken(token);
    onToken && onToken(token);

    finish();
  }, [stripeData, onToken]);

  const clear = useCallback((e) => {
    e.preventDefault();
    setToken(null);
    onToken && onToken(null);
  });

  return (
      <Card {...props}>
        <Dimmer active={isLoading} loader={isLoading}>
          <Card.Body title='Pay In Full With Credit Card'>
            { Boolean(token) ?
              (
                token.error ? (
                  <Alert type='danger' icon='alert-triangle' isDismissible onDismissClick={clear}>
                    {token.error.message}
                  </Alert>
                ) : (
                  <Alert type='success' icon='check'>
                    {'Tokenized Credit Card!'}
                  </Alert>
                )
              ) :
              (<>
                <Form.Input
                  name='cc.name'
                  label='Name'
                  placeholder='Name on Card'
                  value={name}
                  onChange={e => setName(e.currentTarget.value)}
                  />
                <Grid.Row>
                  <Grid.Col xl={6} lg={6} md={6} sm={12} xs={12} width={12}>
                    <Form.Group label='Credit Card #'>
                      <CardNumberElement className='form-control' />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col xl={3} lg={3} md={3} sm={6} xs={6} width={6}>
                    <Form.Group label='Expiration'>
                      <CardExpiryElement className='form-control' />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col xl={3} lg={3} md={3} sm={6} xs={6} width={6}>
                    <Form.Group label='CVC'>
                      <CardCvcElement className='form-control' />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </>)
            }
          </Card.Body>
        </Dimmer>
        <Card.Footer>
          { Boolean(token) ?
            (<Button onClick={clear} color='warning'>Clear</Button>) :
            (<Button onClick={submit}>Tokenize Card</Button>)
          }
        </Card.Footer>
      </Card>
  );
}

const elementize = (Component) => (props) => (
  <Elements>
    <Component {...props} />
  </Elements>
);

export default elementize(injectStripe(StripeCreditCardElement));

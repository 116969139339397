
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { AuthContext } from '../components/AuthContext.js';
import copyToClipboard from '../tools/copyToClipboard.js';
import { Alert, Form, Button } from 'tabler-react';

const BASE_URL = 'https://hnpabc.com/am/';
const ENDPOINT = '/business/register';

export function signupUrlFromSlug(slug) {
  return `${BASE_URL}${String(slug)}${ENDPOINT}`;
}

const SignupLinkView = () => {
  const [, account] = useContext(AuthContext);
  const [copied, setCopied] = useState();
  const [url, setUrl] = useState();

  const slug = account && account.data && account.data.account_manager_token || 'test';

  useEffect(() => {
    if (slug) {
      setUrl(signupUrlFromSlug(slug));
    } else {
      setUrl(null);
    }
  }, [slug]);

  useEffect(() => {
    if (copied !== 0) {
      const timeout = setTimeout(() => {
        setCopied(0);
      }, 3000);
  
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  const copy = useCallback(() => {
    copyToClipboard(url).then(success => {
      console.warn('copied', success);
      setCopied(success ? 1 : -1);
    });
  }, [url]);

  if (url) {
    return (
      <Alert type='secondary'>
        <h2>External Registration Link</h2>
        <p>Your can copy your registration link for those clients that insist on registering themselves:</p>
        <Form.Group>
          <Form.InputGroup>
            <Form.Input
              readOnly
              value={url}
              />
            <Form.InputGroupAppend>
              <Button
                color='primary'
                icon='clipboard'
                onClick={copy}
                >
                Copy to Clipboard
              </Button>
            </Form.InputGroupAppend>
          </Form.InputGroup>
        </Form.Group>
          { copied === 1 && 
            <Alert type='success'>
              Copied to clipboard!
            </Alert>
          }
          { copied === -1 && 
            <Alert type='danger'>
              Unabled to copy to clipboard, please do it manually.
            </Alert>
          }
      </Alert>
    );
  }

  return null;
}

export default SignupLinkView;

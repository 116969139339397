import React from 'react';
import ProtectedContent, { withProtection } from './ProtectedContent.js';
import { Site } from 'tabler-react';
import { NavLink, withRouter } from 'react-router-dom';

const LinkComponent = withRouter(NavLink);

const AdminLinkComponent = withProtection(LinkComponent, {
  validate: (user) => user.role === 'admin'
});

const SalesLinkComponent = withProtection(LinkComponent, {
  validate: (user) => user.role === 'admin' || user.role === 'manager' || user.role === 'sales'
});

const AccountManagerLinkComponent = withProtection(LinkComponent, {
  validate: (user) => user.role === 'admin' || user.role === 'manager'
});

const navBarItems = [{
      value: 'Dashboard',
      to: '/',
      icon: 'check-square',
      LinkComponent: withProtection(LinkComponent, {
        validate: (user, account) => account && account.type === 'business',
        override: {
          exact: true
        }
      })
    }, {
      value: 'Transactions',
      to: '/transactions',
      icon: 'list',
      LinkComponent: withProtection(LinkComponent, {
        validate: (user, account) => user.role === 'admin',
        override: {
          exact: true
        }
      })
    }, {
      value: 'Volunteerism',
      to: '/volunteerism',
      icon: 'list',
      LinkComponent: withProtection(LinkComponent, {
        validate: (user, account) => user.role === 'admin',
        override: {
          exact: true
        }
      })
    }, {
    value: 'Events',
    to: '/events',
    icon: 'list',
    LinkComponent: withProtection(LinkComponent, {
      validate: (user, account) => account && account.type === 'business',
      override: {
        exact: true
      }
    })
  }, {
    value: 'Feedback',
    to: '/feedback',
    icon: 'list',
    LinkComponent: withProtection(LinkComponent, {
      validate: (user, account) => account && account.type === 'business',
      override: {
        exact: true
      }
    })
  }, {
    value: 'Listing',
    to: '/listing',
    icon: 'check-square',
    LinkComponent: withProtection(LinkComponent, {
      validate: (user, account) => account && account.type === 'business'
    })
  }, {
    value: 'Availability',
    to: '/availability',
    icon: 'check-square',
    LinkComponent: SalesLinkComponent
  }, {
    value: 'Register',
    to: '/register',
    icon: 'plus-square',
    LinkComponent: SalesLinkComponent
}, {
  value: 'Notes',
  to: '/notes',
  icon: 'list',
  LinkComponent: SalesLinkComponent
}, {
  value: 'Categories',
  to: '/categories',
  icon: 'list',
  LinkComponent: AdminLinkComponent
}, {
  value: 'Businesses',
  to: '/businesses',
  icon: 'list',
  LinkComponent: AccountManagerLinkComponent
}, {
    value: 'Events',
    to: '/events/all',
    icon: 'list',
    LinkComponent: SalesLinkComponent
}, {
    value: 'Sales Team',
    to: '/sales-team',
    icon: 'user',
    LinkComponent: AdminLinkComponent
}];

const SiteWrapper = ({ children }) => (
  <Site.Wrapper
    navProps={{
      itemsObjects: navBarItems,
      rightColumnComponent: (
        <div style={{textAlign: 'right'}}>
          <NavLink to='/logout'>Logout</NavLink>
        </div>
      )
    }}
    footerProps={{
      copyright: (<>&copy; {new Date().getFullYear()} Happy Neighborhood Project. All Rights Reserved. </>)
    }}
    >
    { children }
  </Site.Wrapper>
);

export default SiteWrapper;


import React, { useCallback, useMemo } from 'react';
import { withFormsy } from 'formsy-react';
import { Form } from 'tabler-react';

const kE = (datum, index) => {
  return (datum && datum.key) || index;
}

const buildOptions = (options = [], value, keyExtractor) => {
  return options.map((o, i) => {
    const label = o.label || o;
    const val = o.value || o;
    const selected = value === val;

    return (
      <option key={keyExtractor(o, i)} selected={selected} value={val}>
        { label }
      </option>
    );
  });
}

const Select = ({
  label, placeholder, options = [], keyExtractor = kE, getValue, setValue, getErrorMessage, ...props
}) => {
  const value = getValue();
  const onChangeValue = useCallback((event) => {
    const { value } = event.currentTarget;

    console.log('value', value)
    setValue(value);
  }, []);

  const _options = useMemo(() => 
    buildOptions(options, value, keyExtractor), 
    [options, value, keyExtractor]
  );

  return (
    <Form.Group isRequired={props.required} label={label}>
      <Form.Select value={value} onChange={onChangeValue} error={getErrorMessage()} {...props}>
        { placeholder && !value  &&
          <option disabled selected default>{placeholder}</option>
        }
        { _options }
      </Form.Select>
    </Form.Group>
  );
}

export default withFormsy(Select);


import React from 'react';

const SquareVideo = ({ src, style, children, ...props }) => (
  <div style={{ width: '100%', paddingTop: '100%', position: 'relative' }}>
    <div style={{ height: '100%', width: '100%', position: 'absolute', top: 0 }}>
      { src && 
        <video 
          src={src} 
          style={{ width: '100%', height: '100%', objectFit: 'cover', ...style }} 
          controls
          {...props}
          >
            { children }
        </video>
      }
    </div>
  </div>
);

export default SquareVideo;
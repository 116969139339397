
import React, { useState, useEffect, useCallback } from 'react';
import { Page, Button } from 'tabler-react';

import { getSalesTeam } from '../API.js';
import TablerTable from '../components/Table/TablerTable.js';
import AddSalesTeamMemberForm from '../components/AddSalesTeamMemberForm.js';
import { signupUrlFromSlug } from '../components/SignupLinkView.js';

const columns = [{
  id: 'name',
  Header: 'Name',
  accessor: m => m.name.first + ' ' + m.name.last
}, {
  id: 'email',
  Header: 'Email',
  accessor: 'email'
}, {
  id: 'slug',
  Header: 'URL Slug',
  accessor: 'account_manager_token',
  Cell: ({ cell: { value } }) => (
    <a href={signupUrlFromSlug(value)}>
      { value || '?' }
    </a>
  )
}];

export default function SalesTeamPage() {
  const [members, setMembers] = useState([]);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    getSalesTeam().then(({ members }) => {
      setMembers(members)
    });
  }, []);

  const onAdd = useCallback(member => {
    setMembers(members => [...members, member]);
    setShowForm(false);
  }, []);

  return (
    <Page.Content title='Sales Team'>
      { showForm ?
        <AddSalesTeamMemberForm onAdd={onAdd} onClose={() => setShowForm(false)} /> :
        <div style={{textAlign: 'center'}}>
          <Button size='lg' onClick={() => setShowForm(true)}>Add Team Member</Button>
        </div>
      }
      <hr />
      <h1 style={{textAlign: 'center'}}>Sales Team Members</h1>
      <TablerTable
        title='Team'
        data={members}
        columns={columns}
        defaultPageSize={10}
        />
    </Page.Content>
  );
}


import React from 'react';
import { Card, Dimmer, Button } from 'tabler-react';
import moment from 'moment';
import useLoading from '../tools/useLoading.js';

export default function EventCard({event = {}, onDelete, ...props}) {
  const { title, description, url, location, tags, starts_at, ends_at } = event;
  const { isLoading, start, finish } = useLoading();

  return (
    <Card title={title} isFullscreenable {...props}>
      <Dimmer active={isLoading} loader={isLoading}>
        <Card.Body>
          <h5>Location: {location}</h5>
          {url &&
            <h5>URL: <a href={url} target="_blank" rel="noreferrer noopener">{url}</a></h5>
          }
          <hr />
          <h5>Description:</h5>
          { String(description || '')
            .split('/n')
            .filter(s => s.trim().length)
            .map((s, i) => <p key={i}>{s}</p>)
          }
          <hr />
          <h5>Start: {moment(starts_at).format('MMMM Do, YYYY @ h:mma')}</h5>
          <h5>End: {moment(ends_at).format('MMMM Do, YYYY @ h:mma')}</h5>
        </Card.Body>
      </Dimmer>
      <Card.Footer>
        <Button
          color='danger'
          size='sm'
          icon='trash'
          onClick={() => onDelete(start, finish)}
          >
          {'Delete'}
        </Button>
      </Card.Footer>
    </Card>
  );
}

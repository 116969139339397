
import React from 'react';
import Input from './Input.js';

const UrlInput = (props) => (
  <Input
    validations='isUrl'
    validationError={`Invalid URL (did your forget 'http://' or 'https')`}
    placeholder='http://example.com'
    {...props}
    />
);

export default UrlInput;


import React, { useState, useEffect } from 'react';
import ls from 'local-storage';
import { api, login as apiLogin, getUserAccount } from '../API.js';

const USER_KEY = 'hnp_user';
const ACCOUNT_KEY = 'hnp_account';
const TOKEN_KEY = 'hnp_token';

const AuthContext = React.createContext([null, () => {}]);

const AuthProvider = (props) => {
  const [user, setUser] = useState(ls(USER_KEY));
  const [account, setAccount] = useState(ls(ACCOUNT_KEY) || {});

  const token = ls(TOKEN_KEY);

  if (token) {
    api.jwt(token);
  }

  const login = (username, password) => {
    return apiLogin(username, password).then(({ user, token }) => {
      if (user && token) {
        ls(TOKEN_KEY, token);
        ls(USER_KEY, user);

        api.jwt(token);
        setUser(user);
        
        return user;
      }

      return Promise.reject(new Error('Login failed!'));
    }).then(user => {
      return getUserAccount().then(({ account, user: alsoUser }) => {
        if (account && account.uuid) {
          ls(ACCOUNT_KEY, account);
          setAccount(account);

          return { user, account };
        }

        return Promise.reject(new Error(`Couldn't get account.`));
      });
    });
  };

  const logout = () => {
    api.jwt();
    ls.remove(TOKEN_KEY);
    ls.remove(USER_KEY);
    ls.remove(ACCOUNT_KEY);
  };

  const updateToken = token => api.jwt(token);

  const checkLoggedIn = user => user ?
    setUser(user) :
    logout();

  const updateAccount = (account, oldAccount) => {
    if (!account || !account.uuid) {
      getUserAccount().then(acct => {
        setAccount(acct);
      });
    } else {
      setAccount(account);
    }
  };

  useEffect(() => {
    ls.on(TOKEN_KEY, updateToken);

    return () => ls.off(TOKEN_KEY, updateToken);
  });

  useEffect(() => {
    ls.on(USER_KEY, checkLoggedIn);

    return () => ls.off(USER_KEY, checkLoggedIn);
  });

  useEffect(() => {
    ls.on(ACCOUNT_KEY, updateAccount);

    return () => ls.off(ACCOUNT_KEY, updateAccount);
  });

  return (
    <AuthContext.Provider value={[user, account, login, logout]}>
      {props.children}
    </AuthContext.Provider>
  );
}

const AuthConsumer = AuthContext.Consumer;

export { AuthContext, AuthProvider, AuthConsumer };


import React, { Component } from 'react';
import { Card, Grid, Form, Button } from 'tabler-react';
import Formsy from 'formsy-react';
import { withRouter } from 'react-router-dom';
import { Input, Select, ZipTags, UrlInput, DatePicker } from './Form';
import AuthorizedSalesTeamSelect from './AuthorizedSalesTeamSelect.js';
import { AuthConsumer } from '../components/AuthContext.js';
import { debounce } from 'throttle-debounce';
import { confirmWithNote } from '../components/Dialog/Confirm.js';
import { alert } from '../components/Dialog/Alert.js';
import StripeCreditCardElement from '../components/StripeCreditCardElement.js';
import STATES from '../tools/misc/StatesUSA.js';

import {
  DEBUG_MODE, checkPricing, getCategories, createBusinessWithListings,
  createBusinessWithListingsFree
} from '../API.js';

const STRIPE_API_KEY = DEBUG_MODE ? 'pk_test_wVcBMBlthusazJyMZPztJ6pz' : 'pk_live_6RBkVlHP5MIW0Z8ol4qA9Ubb';

const stateOptions = STATES.map(s => s.name);

const monthOptions = [...new Array(12)].map((m, i) => String('0' + (i + 1)).substr(-2));
const yearOptions = [...new Array(50)].map((y, i) => String(2019 + i));

const FREE_REGISTRATION_NOTE = (<>
  <p>Are you sure you wish to register a client without processing a card?</p>
  <p>(If so, enter a reason.)</p>
</>);

const defaultState = (extra = {}) => ({
  loading: false,
  valid: false,
  coupon: null,
  zips: [],
  options: {},
  splits: [0],
  ...extra
});

function PaymentSplitInput({ index = 0, onRemove }) {

  return (
    <Grid.Row>
      <Grid.Col xl={6} lg={6} md={6} sm={12} xs={12} width={12}>
        <DatePicker 
          name={`payments[${index}].due`} 
          label='Due' 
          minYear={2020} 
          maxYear={new Date().getFullYear() + 1} 
          format='mm/dd/yyyy' 
          />
      </Grid.Col>
      <Grid.Col xl={6} lg={6} md={6} sm={12} xs={12} width={12}>
        <Input name={`payments[${index}].amount`} type='number' maxLength={3} label='Amount' placeholder='Amount' />
      </Grid.Col>
    </Grid.Row>
  );
}

class AddBusinessForm extends Component {
  state = defaultState()

  componentDidMount() {
    this.updatePricing();

    getCategories().then(({categories: cats}) => {
      this.setState({
        categories: cats.map(c => ({ value: c.uuid, label: c.name }))
      });
    });
  }

  updateOptions = (values = {}) => {
    this.setState({
      options: {
        ...this.state.options,
        ...values
      }
    });
  }

  registerFree = () => {
    const data = this.form.getModel();

    confirmWithNote(FREE_REGISTRATION_NOTE).then(note => {
      this.setState({ loading: true });
      createBusinessWithListingsFree({...data, note}).then(result => {
        if (result && result.error) {
          this.setState({ loading: false });
          console.error(result.error);
        } else {
          this.props.history.push('/register/success');
        }
      });
    });
  }

  onSubmit = async (data, reset, invalidate) => {
    if (this.state.stripeToken) {
      this.setState({ loading: true });
      createBusinessWithListings(data, this.state.stripeToken).then(result => {
        this.setState({ loading: false });
        if (result && (result.error || result.status === 'error')) {
          console.error(result.error || result);
        } else {
          this.props.history.push('/register/success');
        }
      }).catch(e => this.setState({ loading: false }));
    }
  }

  updateCoupon = (coupon) => {
    this.setState({ coupon }, this.updatePricing);
  }

  updatePricing = debounce(350, () => {
    const { coupon, zips = [], loadingPrice = 0 } = this.state;

    this.setState({ loadingPrice: loadingPrice ? loadingPrice + 1 : 1 });
    checkPricing(coupon, zips).then(pricing => {
      this.setState({
        price: pricing.total,
        loadingPrice: loadingPrice ? loadingPrice - 1 : false
      });
    });
  })

  onChange = (data, isChanged) => {
    const { zips = [] } = data;

    if (this.state.zips.length !== zips.length) {
      this.setState({ zips }, () => {
        this.updatePricing();
      });
    }
  }

  enable = () => {
    this.setState({ valid: true });
  }

  disable = () => {
    this.setState({ valid: false });
  }

  render() {
    const {
      loading, valid, categories, price, loadingPrice, zips, coupon, stripeToken
    } = this.state;

    const canSubmit = Boolean(
      !loading && valid && zips.length && stripeToken && !stripeToken.error
    );

    const cost = Boolean(loadingPrice) ?
      'Loading Price...' :
      `$${price ? price / 100 : 0}`;

    return (
      <AuthConsumer>
        { ([user, account]) =>
          <Card>
            <Card.Header>
              <Card.Title>Enter Business Info</Card.Title>
            </Card.Header>
            <Card.Body>
              <Formsy
                ref={ref => this.form = ref}
                onValidSubmit={this.onSubmit}
                onValid={this.enable}
                onInvalid={this.disable}
                onChange={this.onChange}
                >
                <Input name='ref_uuid' type='hidden' value={account && account.uuid} />
                <Input name='ref_type' type='hidden' value='account-manager' />
                <Input name='company' label='Company Name' placeholder='Business Name' required/>
                <Select
                  name='category_uuid'
                  label='Category'
                  options={categories}
                  placeholder='Select a Category...'
                  required
                  />
                <Grid.Row>
                  <Grid.Col xl={6} lg={6} md={6} sm={12} xs={12} width={12}>
                    <Input name='name.first' label='First Name' placeholder='First Name' required />
                  </Grid.Col>
                  <Grid.Col xl={6} lg={6} md={6} sm={12} xs={12} width={12}>
                    <Input name='name.last' label='Last Name' placeholder='Last Name' required />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col xl={4} lg={4} md={4} sm={12} xs={12} width={12}>
                    <Input
                      name='email'
                      validations='isEmail'
                      validationError='Invalid Email'
                      label='Email'
                      required
                      />
                  </Grid.Col>
                  <Grid.Col xl={4} lg={4} md={4} sm={12} xs={12} width={12}>
                    <Input name='phone' label='Phone Number'/>
                  </Grid.Col>
                  <Grid.Col xl={4} lg={4} md={4} sm={12} xs={12} width={12}>
                    <UrlInput name='data.website' label='Website' />
                  </Grid.Col>
                </Grid.Row>
                <hr />
                <Input name='address.street' label='Street Address'/>
                <Grid.Row>
                  <Grid.Col xl={4} lg={4} md={4} sm={12} xs={12} width={12}>
                    <Input name='address.city' label='City'/>
                  </Grid.Col>
                  <Grid.Col xl={4} lg={4} md={4} sm={12} xs={12} width={12}>
                    <Select name='address.state' options={stateOptions} label='State' placeholder=' ' />
                  </Grid.Col>
                  <Grid.Col xl={4} lg={4} md={4} sm={12} xs={12} width={12}>
                    <Input name='address.zip' type='number' validationError='Invalid Zip Code' label='Zip Code'/>
                  </Grid.Col>
                </Grid.Row>
                <hr />
                  <StripeCreditCardElement
                    onToken={stripeToken => this.setState({ stripeToken })}
                    title='Add Credit Card'
                    isCollapsible
                    />
                <hr />
                <AuthorizedSalesTeamSelect />
                <hr />
                <ZipTags
                  name='zips'
                  label='Zip Code(s)'
                  placeholder='Add Zip Codes'
                  />
                <Button disabled={!canSubmit}>Register ({cost})</Button>
                <hr />
                { this.state.splits.map((split, i) => (
                  <PaymentSplitInput index={i} onRemove={() => this.setState({ splits: this.state.splits.filter((s, ix) => i !== ix) })} />
                ))}
                <Button type='button' onClick={() => this.setState({ splits: this.state.splits.concat(this.state.splits.length) })}>Add Split</Button>
                <span style={{ width: '1em' }} />
                <Button type='button' onClick={() => this.setState({ splits: this.state.splits.splice(0, this.state.splits.length - 1) })}>Remove Split</Button>
              </Formsy>
              <hr />
              <Form.Group label='Coupon Code'>
                <Form.Input
                  placeholder='Enter Coupon Code...'
                  onChange={e => this.updateCoupon(e.currentTarget.value)}
                  value={coupon}
                />
              </Form.Group>
              <hr />
              <Button color='warning' disabled={loading || !valid || !zips.length} onClick={this.registerFree}>Register (without payment)</Button>
            </Card.Body>
          </Card>
        }
      </AuthConsumer>
    );
  }
}

export default withRouter(AddBusinessForm);


import React, { useContext } from 'react';
import { Alert } from 'tabler-react';
import { AuthContext } from '../components/AuthContext.js';
import { Input, SalesTeamSelect } from './Form';

export default function AuthorizedSalesTeamSelect() {
  const [user, account] = useContext(AuthContext);

  if (account && account.type === 'account-manager') {
    const name = account.name && account ?
      account.name.first + ' ' + account.name.last :
      account.company;

    return (
      <>
        <Alert type='secondary' icon='user'>
          Account will be attached to <strong>{name}</strong>!
        </Alert>
        <Input name='ref_uuid' value={account.uuid} type='hidden' />
        <Input name='ref_type' value={account.type} type='hidden' />
      </>
    );
  }

  return (
    <>
      <SalesTeamSelect
        name='ref_uuid'
        alphabetical
        />
      <Input name='ref_type' value='account-manager' type='hidden' />
    </>
  );
}

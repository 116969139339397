
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Page, Button } from 'tabler-react';
import moment from 'moment';

import { getCategories } from '../API.js';
import TablerTable from '../components/Table/TablerTable.js';
import ListingsInCategoryByZipModal from '../components/Categories/ListingsInCategoryByZipModal.js';
import AddCategoryForm from '../components/AddCategoryForm.js';
import { editCategory } from '../components/Dialog/EditCategory.js';

const generateColumns = (onUpdateCategory, onViewReport) => [{
  id: 'edit',
  Cell: ({ row }) => (
    <Button.List align='center'>
      <Button color='info' size='sm' onClick={() => 
          onViewReport(row.original)
        }>
        View Report
      </Button>
      <Button color='primary' size='sm' onClick={() => 
          editCategory(row.original).then(onUpdateCategory)
        }>
        Edit
      </Button>
    </Button.List>
  )
}, {
  id: 'name',
  Header: 'Name',
  accessor: 'name'
}, {
  id: 'slug',
  Header: 'URL Slug',
  accessor: 'slug',
}, {
  id: 'created',
  Header: 'Created',
  accessor: 'createdAt',
  Cell: ({ cell: { value } }) => moment(value).format('MMM Do, YYYY')
}];

const CategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [viewReport, setViewReport] = useState();

  useEffect(() => {
    getCategories().then(({categories}) => {
      setCategories(categories);
    });
  }, []);

  const onAdd = useCallback(category => {
    setCategories(categories => [...categories, category]);
    setShowForm(false);
  }, []);

  const onUpdate = useCallback(category => {
    if (category) {
      setCategories(categories => {
        const index = categories.findIndex(cat => cat.uuid === category.uuid);
        categories[index] = category;
  
        return [...categories];
      });
    }
  }, []);

  const onViewReport = useCallback(category => {
    if (category && category.uuid) {
      setViewReport(category.uuid);
    }
  }, []);

  const columns = useMemo(() => generateColumns(onUpdate, onViewReport), [onUpdate, onViewReport]);

  return (
    <Page.Content title='Categories'>
      { viewReport &&
        <ListingsInCategoryByZipModal 
          uuid={viewReport} 
          onClose={() => setViewReport()} 
          />
      }
      { showForm ?
        <AddCategoryForm onAdd={onAdd} onClose={() => setShowForm(false)} /> :
        <div style={{textAlign: 'center'}}>
          <Button size='lg' onClick={() => setShowForm(true)}>
            Add Category
          </Button>
        </div>
      }
      <hr />
      <h1 style={{textAlign: 'center'}}>Categories</h1>
      <TablerTable
        title='Categories'
        data={categories}
        columns={columns}
        defaultPageSize={10}
        />
    </Page.Content>
  );
}

export default CategoriesPage;

import React, { useMemo, useCallback } from 'react';
import { Table, Button, Icon, Dimmer } from 'tabler-react';
import { confirm } from '../Dialog/Confirm.js';

function formatPhone(phone) {
  if (phone) {
    const number = phone.replace(/\D/g,'').slice(-10);
    return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;
  }

  return '';
}

const VolunteerOrganizationList = ({ organizations, onEdit, onDelete, loading }) => {
  const confirmDelete = useCallback(organization => {
    confirm(`Are you sure you wish to delete the volunteer organization: "${organization.name}?"`, 'Delete!?').then(() => {
      onDelete(organization);
    }).catch(() => {});
  }, []);

  const items = useMemo(() => {
    return organizations.map(organization => ({
        key: organization.uuid,
        item: [
          { content: organization.company },
          { content: organization.name },
          { content: organization.phone && <a href={`tel:${organization.phone}`}>{formatPhone(organization.phone)}</a> },
          { content: (
            <Button.List>
              <Button outline color='warning' size='sm' onClick={() => onEdit(organization)}><Icon name='edit' /></Button>
              <Button outline color='danger' size='sm' onClick={() => confirmDelete(organization)}><Icon name='trash' /></Button>
            </Button.List>
          )}
        ]
    }));
  }, [organizations]);

  return (
    <Dimmer active={loading} loader={loading}>
      <Table
        headerItems={[{ content: 'Company' }, { content: 'Contact' }, { content: 'Phone' }, { content: 'Action' }]}
        bodyItems={items}
        />
    </Dimmer>
  );
}

export default VolunteerOrganizationList;

import React, { useState, useEffect, useCallback } from 'react';
import { Card, Dimmer } from 'tabler-react';
import { getZoomMeetings, getZoomMeetingPhoneNumbers, smsZoomRegistrants } from '../API.js';
import { confirm } from '../components/Dialog/Confirm.js';
import classNames from 'classnames';

const MINIMUM_LENGTH = 20;

const DisplayPhones = ({ id, meetings, occurrences, phones = [] }) => {
  const [message, setMessage] = useState('');
  const meeting = meetings.find(m => m.meeting_id === id);
  const location = meeting ? meeting.location : '';
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const send = useCallback(() => {
    confirm(`Are you sure wish to send "${message}" to ${phones.length} people for the ${location} meeting?`, 'Confirm SMS Message').then(() => {
      setLoading(true);
      setError();
      setSuccess();
      smsZoomRegistrants(id, message).then(result => {
        setLoading(false);
  
        if (result.error) {
          setError(result.error);
        } else {
          setMessage('');
          setSuccess(true);
        }
      });
    });
  }, [id, message, phones, location]);
  
  return (
    <div>
      <h3>Phone Numbers for {location} ({id})</h3>
      <h4>Past {occurrences} Weeks Registrants <small>({phones.length} Numbers)</small></h4>
      <textarea contentEditable={false} className='form-control' style={{ minHeight: '10em' }} value={phones.join(', ')} />
      <hr />
      <h2>Send Message</h2>
      <textarea 
        className='form-control' 
        placeholder='Enter message here...' 
        maxLength={160} value={message} 
        onChange={e => setMessage(e.target.value)} 
        />
      { Boolean(error) && 
        <div className='mt-2 alert alert-danger text-center'>
          <span>{ error }</span>
        </div>
      }
      { Boolean(success) && 
        <div className='mt-2 alert alert-success text-center'>
          <span>Successfully sent message!</span>
        </div>
      }
      <div className='mt-2 text-right'>
        <span className='mr-2'>{message.length} of 160 characters</span>
        <button 
          onClick={() => send()} 
          className={classNames('btn btn-primary', { 'btn-loading': loading })} 
          disabled={message.length < MINIMUM_LENGTH || loading}
          >
          Send
        </button>
      </div>
    </div>
  )
};

export default function ZoomPhoneNumbers() {
  const [loading, setLoading] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [selected, setSelected] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    setLoading(true);
    getZoomMeetings().then(({meetings}) => {
      setLoading(false);
      setMeetings(meetings);
    });
  }, []);

  useEffect(() => {
    if (selected) {
      setLoading(true);
      getZoomMeetingPhoneNumbers(selected).then((data) => {
        setLoading(false);
        setData(data);
      });
    }
  }, [selected]);

  return (
    <Card>
      <Card.Header>
        <h2>Zoom Meeting Phone Numbers</h2>
      </Card.Header>
      <Dimmer active={loading} loader={loading}>
        <Card.Body style={{minHeight: '1em'}}>
          { Boolean(meetings) && 
            <select className='form-control' value={selected} onChange={e => setSelected(e.target.value)}>
              <option default select>Select a Meeting</option>
              {
              meetings.map((m, i) => 
                <option key={i} value={m.meeting_id}>
                  {m.location} ({m.meeting_id})
                </option>
              )}
            </select>
          }
          <hr />
          { Boolean(selected && data) && 
            <DisplayPhones 
              id={selected} 
              meetings={meetings}
              phones={data.phones} 
              occurrences={data.occurrences}
              /> 
          }
        </Card.Body>
      </Dimmer>
    </Card>
  );
}
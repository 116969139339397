
import React from 'react';
import { Page } from 'tabler-react';
import AddBusinessForm from '../../components/AddBusinessForm.js';
import SignupLinkView from '../../components/SignupLinkView.js';

const AddBusinessPage = () => (
  <Page.Content title='Add a Business'>
    <SignupLinkView />
    <AddBusinessForm />
  </Page.Content>
);

export default AddBusinessPage;

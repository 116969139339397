
import React, { useRef, useMemo } from 'react';
import { Card, Alert, Grid, Form, Text, Header, Dimmer, Tag, GalleryCard } from 'tabler-react';
import SquareVideo from './SquareVideo.js';
import nl2br from '../tools/nl2br.js';

export default function ListingCard({listing = {}, zips = [], loading}) {
  const { name, blurb, description, happy_reward, keywords, address = {}, email, 
  phone, website, logo, video } = listing;
  const card = useRef();

  const toggleCollapse = () => card.current && card.current.handleCollapseOnClick();
  const toggleFullscreen = () => card.current && card.current.handleFullscreenOnClick();

  return (
    <Card ref={ref => card.current = ref} isCollapsible>
      <Card.Header>
        <Card.Title>Current Listing</Card.Title>
        <Card.Options>
          <ZipCodes zips={zips} />
          <div style={{marginHorizontal: 10}} />
          <Card.OptionsItem onClick={toggleFullscreen} type='fullscreen' />
          <Card.OptionsItem onClick={toggleCollapse} type='collapse' />
        </Card.Options>
      </Card.Header>
      <Dimmer active={loading || !name} loader={loading}>
        <GalleryCard.Image
          as='div'
          rounded={false}
          src={logo ? logo.url : null}
          />
        { happy_reward &&
          <Card.Alert color='success' icon='star' className='text-center'>
            <h4 className='mb-0'>Happy Reward!</h4>
            <Text>{ happy_reward }</Text>
          </Card.Alert>
        }
        <Card.Body>
          <Header.H2>{name}</Header.H2>
          <Header.H4>{blurb}</Header.H4>
          <p>{ nl2br(description) }</p>
          { video && video.url &&
            <SquareVideo src={video.url} style={{ backgroundColor: 'grey' }} controls />
          }
          { address && 
            <>
              <hr />
              <p>
                { address.street }<br />
                { address.city }, { address.state } { address.zip }
              </p>
            </>
          }
          <hr />
          { email && 
            <p>Email: <a href={`mailto:${email}`}>{ email }</a></p>
          }
          { phone && 
            <p>Phone: <a href={`tel:${phone}`}>{ phone }</a></p>
          }
          { website && 
            <p>Website: <a href={website}>{ website }</a></p>
          }
        </Card.Body>
        <Card.Footer>
          <Keywords keywords={keywords} title='Keywords' />
        </Card.Footer>
      </Dimmer>
    </Card>
  );
}

const ZipCodes = ({zips = [], title}) => (
  <Grid.Row>
    {title &&
      <Grid.Col auto>
        <Header.H5 className='mt-1'>
          {typeof title === 'string' ? title : 'Zips'}
        </Header.H5>
      </Grid.Col>
    }
    <Grid.Col>
      <Tag.List>
        {Array.isArray(zips) && zips.map(({zip, category, leader}, i) => (
          <Tag
            key={i}
            addOn={category}
            addOnColor={leader ? 'success' : 'default'}
            >
            {zip}
          </Tag>
        ))}
      </Tag.List>
    </Grid.Col>
  </Grid.Row>
);

const Keywords = ({keywords = [], title}) => (
  <Grid.Row>
    {title &&
      <Grid.Col auto>
        <Header.H5 className='mt-1'>
          {typeof title === 'string' ? title : 'Keywords'}
        </Header.H5>
      </Grid.Col>
    }
    <Grid.Col>
      <Tag.List>
        {Array.isArray(keywords) && keywords.map((keyword, index) => (
          <Tag
            key={index}
            rounded
            >
            {keyword}
          </Tag>
        ))}
      </Tag.List>
    </Grid.Col>
  </Grid.Row>
);
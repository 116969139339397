
import React, { useState, useCallback } from 'react';
import { Card, Grid, Button } from 'tabler-react';
import Formsy from 'formsy-react';
import { Input } from './Form';

import { addCategory } from '../API.js';
import useLoading from '../tools/useLoading.js';

const AddCategoryForm = ({onClose, onAdd}) => {
  const { loading, start, finish } = useLoading();
  const [valid, setValid] = useState(false);

  const onSubmit = useCallback(async (data, reset) => {
    try {
      start();
      const { category } = await addCategory(data);
      reset();
      finish();
      onAdd(category);
    } catch(error) {
      finish();
    }
  }, []);

  return (
    <Card>
      <Card.Header>
        <Card.Title>Add Category</Card.Title>
      </Card.Header>
      <Card.Body>
        <Formsy
          onValidSubmit={onSubmit}
          onValid={() => setValid(true)}
          onInvalid={() => setValid(false)}
          >
          <Grid.Row>
            <Grid.Col xl={6} lg={6} md={6} sm={12} xs={12} width={12}>
              <Input name='name' label='Category Name' placeholder='Category Name' required />
            </Grid.Col>
            <Grid.Col xl={6} lg={6} md={6} sm={12} xs={12} width={12}>
              <Input name='slug' label='URL Slug' placeholder='category-name' />
            </Grid.Col>
          </Grid.Row>
          <Button.List>
            <Button type='submit' color='success' disabled={loading || !valid}>Add</Button>
            <Button color='danger' disabled={loading} onClick={onClose}>Cancel</Button>
          </Button.List>
        </Formsy>
      </Card.Body>
    </Card>
  );
}

export default AddCategoryForm;


import React, { useState, useCallback } from 'react';
import { Card, Form, Button, Alert, Dimmer } from 'tabler-react';
import { Link } from 'react-router-dom';

import StandaloneFormPage from '../components/StandaloneFormPage.js';
import useLoading from '../tools/useLoading.js';
import { loginRequestReset } from '../API.js';

const LoginForgotPage = ({location}) => {
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState();
  const { isLoading, start, finish } = useLoading();

  const onSubmit = useCallback(e => {
    e.preventDefault();
    setError(null);
    
    if (email && email.length && email.indexOf('@') > -1) {
      start();

      loginRequestReset(email).then(user => {
        setSent(true);
        finish();
      }).catch(error => {
        finish();
        setError(error && error.message || 'An error occurred.');
        console.error(error);
      });
    } else {
      setError('Invalid Email Address');
    }
  }, [email]);

  const onChange = useCallback(e => {
    setEmail(e.currentTarget.value);
  }, []);

  return (
    <StandaloneFormPage imageURL={'/images/hnp-logo-150x.png'}>
      <Form className="card" onSubmit={onSubmit}>
        <Card.Body className="p-6">
          <Card.Title RootComponent="div">{'Reset Password'}</Card.Title>
          { !sent && 
            <Dimmer active={isLoading} loader={isLoading}>
              <Form.Input
                name='email'
                label='Email'
                type='email'
                placeholder='Your Email'
                onChange={onChange}
                value={email}
                />
              <Form.Footer>
                {error &&
                  <Alert type="danger" icon="alert-triangle">
                    {error}
                  </Alert>
                }
                <Button type="submit" color="primary" isLoading={isLoading} disabled={isLoading} block={true}>
                  {'Request Password Reset!'}
                </Button>
                <Button RootComponent={Link} to='/login' color="default" disabled={isLoading} block={true}>
                  {'I have an account.'}
                </Button>
              </Form.Footer>
            </Dimmer>
          }
          { sent &&
            <p>If there is an account associated with your email ({email}), you will receive a link to reset your password shortly.</p>
          }
        </Card.Body>
      </Form>
    </StandaloneFormPage>
  );
}

export default LoginForgotPage;


import React, { Component } from 'react';
import { Card, Grid, Header, Form } from 'tabler-react';
import TablerTable from '../components/Table/TablerTable.js';

import { getCategories, checkCategoryAvailability } from '../API.js';

const columns = [{
  id: 'zip',
  Header: 'Zip',
  accessor: 'zip'
}, {
  id: 'distance',
  Header: 'Distance',
  accessor: z => z.distance + ' miles'
}, {
  id: 'status',
  Header: 'Status',
  accessor: z => z.available ?
    'Open (no businesses)' :
    `Occupied ${z.count} (${ z.count === 1 ? 'business' : 'businesses'})`
}];

export default class CategoryAvailabilitySearch extends Component {
  state = {
    z: 95814
  }

  componentDidMount() {
    getCategories().then(({categories}) => {
      this.setState({ categories });
    });
  }

  onZipChange = e => {
    const z = String(e.target.value).replace(/\D/g, '');
    const old = this.state.z;

    if (z !== old) {
      this.setState({ z }, this.search);
    }
  }

  onCategoryChange = e => {
    const category_uuid = String(e.target.value);
    const old = this.state.category_uuid;

    if (category_uuid && category_uuid !== old) {
      this.setState({ category_uuid }, this.search);
    }
  }

  search = () => {
    const { category_uuid, z } = this.state;

    if (!category_uuid || String(z).length !== 5) {
      return;
    }

    this.setState({ loading: true });

    checkCategoryAvailability(category_uuid, z)
      .then(({ zip, available, count, nearby }) => {
        this.setState({
          zip, available, count, nearby,
          category: this.state.categories.find(cat => cat.uuid === category_uuid),
          loading: false
        });
      });
  }

  renderCategoriesSelect() {
    const { categories = [] } = this.state;

    const options = (Array.isArray(categories) ? categories : []).map(category => (
      <option key={category.uuid} value={category.uuid}>
        {category.name}
      </option>
    ));

    return (
      <Form.Group label='Category'>
        <Form.Select onChange={this.onCategoryChange}>
          <option disabled selected default>Select a Category..</option>
          { options }
        </Form.Select>
      </Form.Group>
    );
  }

  renderZipInput() {
    const z = String(this.state.z || '');
    const zip = String(this.state.zip || '');

    return (
      <Form.Input
        label='Zip Code'
        placeholder='Zip Code...'
        value={z}
        type='numeric'
        error={zip && z.length !== 5}
        onChange={this.onZipChange}
        />
    );
  }

  renderNearbyTable() {
    const { nearby = [] } = this.state;

    if (!nearby || !nearby.length) {
      return null;
    }

    return (
      <div>
        <hr />
        <TablerTable
          title='Nearby Zip Codes'
          data={nearby}
          columns={columns}
          defaultPageSize={5}
          />
      </div>
    );
  }

  render() {
    const { loading, zip, available, category, count } = this.state;

    return (
      <Card>
        <Card.Header>
          <Card.Title>Check Category Availability by Zip Code</Card.Title>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col>{ this.renderCategoriesSelect() }</Grid.Col>
            <Grid.Col>{ this.renderZipInput() }</Grid.Col>
          </Grid.Row>
        </Card.Body>
        { !loading && zip &&
          <Card.Body>
            <Header.H2 className='text-center'>
              { available ?
                <span>
                  {category ? category.name : ''} is <span style={{color: 'green'}}>open</span> in {zip || '00000'}!
                </span> :
                <span>
                  {category ? category.name : ''} is <span style={{color: 'red'}}>occupied</span> in {zip || '00000'}.
                </span>
              }
            </Header.H2>
            { !available &&
              <Header.H4 className='text-center'>
                There are currently {count} businesses in that zip code.!
              </Header.H4>
            }
            { this.renderNearbyTable() }
          </Card.Body>
        }
      </Card>
    );
  }
}


import React, { useCallback } from 'react';
import { withFormsy } from 'formsy-react';
import { Form } from 'tabler-react';

function Toggle({ getValue, setValue, getErrorMessage, ...props}) {
  const changeValue = useCallback((e) => {
    setValue(!getValue());
  }, [setValue, getValue]);

  return (
    <Form.Switch
      {...props}
      checked={getValue()}
      onChange={changeValue}
      />
  );
}

export default withFormsy(Toggle);


import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Alert } from 'tabler-react';
import { SkyLightStateless } from 'react-skylight';
import { getCategory, getListingsInCategoryByZip } from '../../API.js';
import TablerTable from '../Table/TablerTable.js';

const ListingsInCategoryByZipModal = ({ uuid, onClose }) => {
  const [category, setCategory] = useState();
  const [report, setReport] = useState();

  useEffect(() => {
    getCategory(uuid).then(({ category }) => {
      setCategory(category);
    });
  }, [uuid]);

  useEffect(() => {
    getListingsInCategoryByZip(uuid).then((data) => {
      console.log('getListingsInCategoryByZip', data);
      setReport(data);
    });
  }, [uuid]);

  const [data, columns] = useMemo(() => {
    const { result = {} } = report || {};

    const columns = [{
      Header: 'Zip',
      accessor: 'zip'
    }, {
      Header: 'Count',
      accessor: 'count',
    }];

    const data = Object.keys(result).map(zip => ({
      zip,
      count: result[zip]
    }));

    return [data, columns];
  }, [report]);

  return (
    <SkyLightStateless
      onCloseClicked={onClose}
      onOverlayClicked={onClose}
      isVisible={Boolean(uuid)}
      dialogStyles={{ marginLeft: '-40%', width: '80%' }}
      closeButtonStyle={{display: 'none'}}
      >
      { Boolean(category && report) &&
        <Alert type='info'>
          Category '{category.name}' has {report.totalCount} listings total.
        </Alert>
      }
      <TablerTable
        title='Listings in Category By Zip'
        columns={columns}
        data={data}
        defaultPageSize={5}
        />
    </SkyLightStateless>
  );
}

export default ListingsInCategoryByZipModal;

import React, { useMemo, useState, useEffect } from 'react';
import { Dimmer, Button, ContactCard } from 'tabler-react';
import Modal from 'react-responsive-modal';

import { getListingsByZip } from '../API.js';
import TablerTable from '../components/Table/TablerTable.js';
import DisplayJSON from '../components/DisplayJSON.js';
import ListingCardModal from './ListingCardModal.js';

function createColumns(select) {
  return [{
    id: 'company',
    Header: 'Company',
    accessor: listing => (
      <Button link onClick={() => select(listing)}>
        { listing.name }
      </Button>
    )
  }, {
    id: 'category',
    Header: 'Category',
    accessor: 'category.name'
  }];
}

const ListingsByZip = ({ zip }) => {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState();

  const columns = useMemo(() => {
    return createColumns(setSelected);
  }, [setSelected]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const { listings } = await getListingsByZip(zip);
        setListings(listings);
      } catch(error) {
        console.warn('getListingsByZip', error);
      }

      setLoading(false);
    })();
  }, [zip]);

  return (
    <Dimmer active={loading} loader={loading}>
      <TablerTable
        title={`Other Companies in ${zip}`}
        data={listings}
        columns={columns}
        defaultPageSize={10}
        />
      <ListingCardModal 
        listing={selected} 
        onClose={() => setSelected()} 
        />
    </Dimmer>
  );
}

export default ListingsByZip;


import React, { useState, useEffect } from 'react';
import { Page, Icon } from 'tabler-react';
import moment from 'moment';

import { getFeedback } from '../API.js';
import TablerTable from '../components/Table/TablerTable.js';

const columns = [{
  Header: 'Name',
  accessor: 'name'
}, {
  Header: 'Email',
  accessor: 'email',
  Cell: ({ cell: { value } }) => Boolean(value) && (
    <a href={`mailto:${value}`}>{value}</a>
  )
}, {
  Header: 'Message',
  accessor: 'message'
}, {
  Header: 'Date',
  accessor: 'createdAt',
  Cell: ({ cell: { value } }) => moment(value).format('MMM Do, YYYY')
}, {
  Header: 'Rating',
  accessor: 'rating',
  Cell: ({ cell: { value } }) => {
    const rating = parseInt(value, 10);
    const stars = [1,2,3,4,5].map(v => (
      <Icon key={v} name={rating >= v ? 'star' : 'star-hollow'} />
    ));

    console.log('rating', rating, stars);

    return (
      <span title={`${rating} out of 5 stars`}>{stars}</span>
    );
  }
}];

const FeedbackPage = () => {
  const [feedback, setFeedback] = useState([]);

  useEffect(() => {
    getFeedback().then(({feedback}) => {
      setFeedback(feedback);
    });
  }, []);

  return (
    <Page.Content title='Your Feedback'>
      <TablerTable
        title='Feedback'
        data={feedback}
        columns={columns}
        defaultPageSize={10}
        />
    </Page.Content>
  );
}

export default FeedbackPage;


import React, { useState, useEffect, useCallback } from 'react';
import { Page, Grid } from 'tabler-react';
import { getOwnListings, updateOwnListing } from '../API.js';
import ListingCard from '../components/ListingCard.js';
import UpdateListingCard from '../components/UpdateListingCard.js';
import useLoading from '../tools/useLoading.js';

export default function ListingPage({ account }) {
  const { isLoading, start, finish } = useLoading();
  const [listings, setListings] = useState([]);

  const refresh = useCallback(() => {
    start();
    getOwnListings().then(({ listings }) => {
      setListings(listings || []);
      finish();
    }).catch(finish);
  }, [start, finish]);

  const updateListings = useCallback((update) => {
    start();
    updateOwnListing(update).then(({ listings }) => {
      setListings(listings);
      finish();
    });
  }, [start, finish]);

  useEffect(() => {
    refresh();
  }, []);

  const zips = listings.map(l => ({
    zip: l.zip,
    category: l.category ? l.category.name : 'n/a'
  }));
  
  const listing = listings[0];

  return (
    <Page.Content title='Listing'>
      <Grid>
        <Grid.Row>
          <Grid.Col xl={4} lg={6} md={6} sm={12} xs={12}>
            <ListingCard
              loading={isLoading}
              listing={listing}
              zips={zips}
              />
          </Grid.Col>
          <Grid.Col xl={8} lg={6} md={6} sm={12} xs={12}>
            <UpdateListingCard listing={listing} onUpdateListing={updateListings} /> 
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Page.Content>
  );
}

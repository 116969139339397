
import { useState, useCallback } from 'react';

export default function useLoading() {
  const [count, setCount] = useState(0);

  const start = useCallback(() => {
    setCount(c => c + 1);
  });

  const finish = useCallback(() => {
    setCount(c => c - 1);
  });

  return {
    isLoading: count > 0,
    loading: count > 0,
    start,
    finish
  };
}

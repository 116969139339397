
import React, { useState, useCallback, useRef, useEffect } from 'react';
import Formsy from 'formsy-react';
import { Input, Select, Tags, TextArea, ImageUploadInput, VideoUploadInput, Toggle, UrlInput } from './Form';
import { Card, Button, Dimmer, Grid, Form } from 'tabler-react';
import { flatten } from '../tools/useful.js';
import useLoading from '../tools/useLoading.js';
import STATES from '../tools/misc/StatesUSA.js';

const stateOptions = STATES.map(s => ({ label: s.name, value: s.name }));

export default function UpdateListingCard({ listing, onUpdateListing }) {
  const form = useRef();
  const [canSubmit, setCanSubmit] = useState(false);
  const [exists, setExists] = useState(false);
  const { isLoading, start, finish } = useLoading();

  const onUpdate = useCallback((model) => {
    if (onUpdateListing) { // TODO use 'changed' to only send up touched fields
      onUpdateListing(model, start, finish);
    }
  }, [onUpdateListing]);

  useEffect(() => {
    if (form.current) {
      if (listing) {
        form.current.reset(flatten(listing));
        setExists(Boolean(listing.uuid));
      }
    }
  }, [listing, form.current]);

  const disabled = isLoading || !canSubmit || (form.current && form.current.props.isPristine());

  return (
    <Card title='Update Listing' isCollapsible isFullscreenable>
      <Dimmer active={isLoading} loader={isLoading}>
        <Card.Body>
          <Formsy
            ref={ref => form.current = ref}
            onValidSubmit={onUpdate}
            onValid={() => setCanSubmit(true)}
            onInvalid={() => setCanSubmit(false)}
            >
            <Input name='name' label='Name' placeholder='Listing title' />
            <ImageUploadInput name='logo_uuid' label='Logo' />
            <VideoUploadInput 
              name='video_uuid' 
              label='Featured Video (.mp4, square aspect-ratio, 100mb max)' 
              />
            <Input name='blurb' label='Blurb' placeholder='Subtitle / Tagline' />
            <Tags name="keywords" label='Keywords' placeholder="Add keyword ('Enter' to submit)..." />
            <TextArea name="description" label='Description' placeholder="Enter listing text" />
            <Input name='happy_reward' label='Happy Reward' placeholder='Special Happy Reward' />
            <hr />
            <Input name="email" label='Email' placeholder='Public contact email' validations="isEmail" validationError="This is not a valid email" />
            <Input name='phone' label='Phone' placeholder='Public Contact Phone Number' />
            <UrlInput name='website' label='Website' />
            <hr />
            <Input name='address.street' label='Street Address'/>
            <Grid.Row>
              <Grid.Col xl={4} lg={4} md={4} sm={12} xs={12} width={12}>
                <Input name='address.city' label='City'/>
              </Grid.Col>
              <Grid.Col xl={4} lg={4} md={4} sm={12} xs={12} width={12}>
                <Select name='address.state' options={stateOptions} label='State' placeholder='Select a state...' />
              </Grid.Col>
              <Grid.Col xl={4} lg={4} md={4} sm={12} xs={12} width={12}>
                <Input name='address.zip' type='number' validationError='Invalid Zip Code' label='Zip Code'/>
              </Grid.Col>
            </Grid.Row>
            <hr />
            <Form.Group>
              <Toggle label='Pending' name='pending' />
            </Form.Group>
            <Button type='submit' disabled={disabled}>Update</Button>
          </Formsy>
        </Card.Body>
      </Dimmer>
    </Card>
  )
}

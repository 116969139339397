
import React, { useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext.js';
import { Redirect } from 'react-router-dom';
import { Button } from 'tabler-react';

const Logout = () => {
  const [user, account, login, logout] = useContext(AuthContext);

  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <Redirect to='/login' />
  );
}

export const LoginButton = () => {
  const [user, account, login] = useContext(AuthContext);

  return (
    <Button onClick={() => login('oso', 'osolive')}>Login</Button>
  );
}

export default Logout;

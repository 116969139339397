
import React, { useState, useCallback, useRef, useEffect } from 'react';
import Formsy from 'formsy-react';
import { Input, Select, Tags, TextArea, DatePicker, DateTimePicker, UrlInput } from './Form';
import { Card, Button, Dimmer, Grid } from 'tabler-react';
import { flatten } from '../tools/useful.js';
import useLoading from '../tools/useLoading.js';
import STATES from '../tools/misc/StatesUSA.js';

const stateOptions = STATES.map(s => ({ label: s.name, value: s.name }));

export default function UpdateEventCard({ event: _event, onUpdateEvent }) {
  const form = useRef();
  const [canSubmit, setCanSubmit] = useState(false);
  const [exists, setExists] = useState(false);
  const { isLoading, start, finish } = useLoading();

  const onFinish = useCallback((clear) => {
    if (clear && form.current) {
      form.current.reset();
    }

    finish();
  });

  const onUpdate = useCallback((model) => {
    if (onUpdateEvent) { // TODO use 'changed' to only send up touched fields
      onUpdateEvent(model, start, onFinish);
    }
  }, [onUpdateEvent]);

  useEffect(() => {
    if (form.current) {
      setExists(Boolean(_event && _event.uuid));
      if (_event) {
        form.current.reset(flatten(_event));
      }
    }
  }, [_event, form.current]);

  const disabled = isLoading || !canSubmit || (form.current && form.current.props.isPristine());

  return (
    <Card title={(exists ? 'Update' : 'Add') + ' Event'} isCollapsible isFullscreenable>
      <Dimmer active={isLoading} loader={isLoading}>
        <Card.Body>
          <Formsy
            ref={ref => form.current = ref}
            onValidSubmit={onUpdate}
            onValid={() => setCanSubmit(true)}
            onInvalid={() => setCanSubmit(false)}
            >
            <Input name='title' label='Name' placeholder='Event Title' required />
            <TextArea name="description" label='Description' placeholder="Enter event description..." required />
            <UrlInput name='url' label='Website' placeholder='https://yourtickets.com' />
            <Tags name="tags" label='Tags' placeholder="Add tag ('Enter' to submit)..." />
            <hr />
            <Input name='location' label='Location' placeholder='Event Location' />

            <Grid.Row>
              <Grid.Col xl={6} lg={6} md={6} sm={12} xs={12} width={12}>
                <DateTimePicker
                  name='starts_at'
                  label='Start'
                  required
                  />
              </Grid.Col>
              <Grid.Col xl={6} lg={6} md={6} sm={12} xs={12} width={12}>
                <DateTimePicker
                  name='ends_at'
                  label='End'
                  required
                  />
              </Grid.Col>
            </Grid.Row>
            <hr />
            <Button type='submit' disabled={disabled}>{exists ? 'Update' : 'Add'} Event</Button>
          </Formsy>
        </Card.Body>
      </Dimmer>
    </Card>
  )
}


import React, { useCallback } from 'react';
import { withFormsy } from 'formsy-react';
import { Form } from 'tabler-react';

function DatePicker({ getValue, setValue, getErrorMessage, label, required, ...props}) {
  const changeValue = useCallback((date) => {
    setValue(date);
  });

  return (
    <Form.Group label={label} isRequired={required}>
      <Form.DatePicker
        {...props}
        required={required}
        value={getValue() || ''}
        error={getErrorMessage()}
        onChange={changeValue}
        />
    </Form.Group>
  );
}

export default withFormsy(DatePicker);

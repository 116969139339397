
import React from 'react'
import { SkyLightStateless } from 'react-skylight';
import { confirmable, createConfirmation } from 'react-confirm';
import { Card, Button } from 'tabler-react';

export const Alert = confirmable(
  ({show, proceed, dismiss, message}) => (
    <SkyLightStateless
      onCloseClicked={dismiss}
      onOverlayClicked={dismiss}
      isVisible={show}
      dialogStyles={{minHeight: 10}}
      closeButtonStyle={{display: 'none'}}
      >
      <style>{'.mb0 { margin-bottom: 0; }'}</style>
      <Card className='mb0'>
        <Card.Body>
          { message }
        </Card.Body>
        <Card.Footer>
          <Button color='primary' onClick={proceed}>Ok</Button>
        </Card.Footer>
      </Card>
    </SkyLightStateless>
  )
);

const callAlert = createConfirmation(Alert);
export const alert = message => {
  return callAlert({ message });
}

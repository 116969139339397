
import React, { useState } from 'react'
import { SkyLightStateless } from 'react-skylight';
import { confirmable, createConfirmation } from 'react-confirm';
import { Card, Form, Button } from 'tabler-react';

export const Confirm = confirmable(({show, proceed, dismiss, cancel, message, title, hasNote}) => {
  const [note, setNote] = useState('');

  return (
    <SkyLightStateless
      onCloseClicked={dismiss}
      onOverlayClicked={dismiss}
      isVisible={show}
      dialogStyles={{minHeight: 10}}
      closeButtonStyle={{display: 'none'}}
      >
      <style>{'.mb0 { margin-bottom: 0; }'}</style>
      <Card className='mb0'>
        <Card.Header>
          <Card.Title>Are you sure?</Card.Title>
        </Card.Header>
        <Card.Body>
          { message }
          { hasNote &&
            <Form.Input
              onChange={e => setNote(e.currentTarget.value)}
              value={note}
              />
          }
        </Card.Body>
        <Card.Footer>
          <Button.List>
            <Button onClick={cancel}>Cancel</Button>
            <Button
              disabled={hasNote && (!note || !note.length)}
              color='danger'
              onClick={() => proceed(note)}
              >
                { title || 'Ok' }
            </Button>
          </Button.List>
        </Card.Footer>
      </Card>
    </SkyLightStateless>
  );
});

const callConfirm = createConfirmation(Confirm);

export const confirm = (message, title) => {
  return callConfirm({ message, title });
}

export const confirmWithNote = (message, title) => {
  return callConfirm({ message, title, hasNote: true });
}


import React, { useEffect, useState } from 'react';
import SiteWrapper from './components/SiteWrapper.js';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

import ripRouteProps from './tools/ripRouteProps.js';

import { AuthProvider } from './components/AuthContext.js';
import ProtectedRoute, { AdminRoute, SalesRoute, BusinessRoute, AccountManagerRoute, LoginRequired } from './components/ProtectedRoute.js';
import LoginAlert from './components/LoginAlert.js';
import LoginPage from './pages/Login.js';
import LoginForgotPage from './pages/LoginForgot.js';
import LoginResetPage from './pages/LoginReset.js';
import Logout from './components/Logout.js';
import DebugMode from './components/DebugMode.js';
import DashboardPage from './pages/Dashboard.js';
import TestPage from './pages/Test.js';
import AvailabilityPage from './pages/Availability.js';
import AddBusinessPage from './pages/register/AddBusiness.js';
import AddBusinessSuccessPage from './pages/register/AddBusinessSuccess.js';
import SalesTeamPage from './pages/SalesTeam.js';
import BusinessesPage from './pages/Businesses.js';
import BusinessPage from './pages/Business';
import ListingPage from './pages/Listing.js';
import EventsPage from './pages/EventsPage.js';
import OwnEventsPage from './pages/Events.js';
import EventPage from './pages/EventPage.js';
import FeedbackPage from './pages/Feedback.js';
import CategoryPage from './pages/Categories.js';
import NotesPage from './pages/Notes.js';
import TransactionsPage from './pages/Transactions.js';
import VolunteerismPage from './pages/Volunteerism.js';
import ProxiesPage from './pages/Proxies.js';
import ZoomInfoPage from './pages/ZoomInfo.js';
import ZoomAttendeesPage from './pages/ZoomAttendees.js';
import GhsRegistrantsPage from './pages/GhsRegistrants.js';

import { DEBUG_MODE, LOCAL_MODE } from './API.js';
import { StripeProvider } from 'react-stripe-elements';

const STRIPE_API_KEY = DEBUG_MODE ? 'pk_test_wVcBMBlthusazJyMZPztJ6pz' : 'pk_live_6RBkVlHP5MIW0Z8ol4qA9Ubb';

const apollo = new ApolloClient({
  uri: 'https://hnp-api-dev.herokuapp.com/graphql',
  cache: new InMemoryCache()
});

function App() {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    if (window.Stripe) {
      setStripe(window.Stripe(STRIPE_API_KEY));
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        setStripe(window.Stripe(STRIPE_API_KEY));
      });
    }
  }, []);

  return (
    <AuthProvider>
      <ApolloProvider client={apollo}>
        <DebugMode 
          enabled={DEBUG_MODE || LOCAL_MODE} 
          message={DEBUG_MODE ? 'Debug Mode' : 'Local Mode'}
          />
        <StripeProvider stripe={stripe}>
          <Router>
            <Switch>
              <Route path='/logout' exact component={Logout} />
              <Route path='/login' exact component={LoginPage} />
              <Route path='/login/forgot' exact component={LoginForgotPage} />
              <Route path='/login/reset/:code/:email64' exact component={LoginResetPage} />
              <Route>
                <LoginRequired path='/' />
                <SiteWrapper>
                  <Route path='/attendees' exact component={ZoomAttendeesPage} />
                  <Route path='/ghs-registrants' exact component={GhsRegistrantsPage} />
                  <ProtectedRoute path='/proxies' component={ProxiesPage} exact />
                  <ProtectedRoute path='/zoom' exact component={ZoomInfoPage} />
                  <ProtectedRoute path='/transactions' component={TransactionsPage} exact />
                  <ProtectedRoute path='/volunteerism' component={VolunteerismPage} exact />
                  <BusinessRoute path='/' exact component={DashboardPage} />
                  <BusinessRoute path='/feedback' component={FeedbackPage} />
                  <BusinessRoute path='/events' component={OwnEventsPage} />
                  <AdminRoute path='/categories' exact component={CategoryPage} />
                  <SalesRoute path='/notes' exact component={NotesPage} />
                  <AdminRoute path='/' exact component={TestPage} noRedirect />
                  <SalesRoute path='/' noRedirect component={AvailabilityPage} />
                  <SalesRoute path='/availability' component={AvailabilityPage} />
                  <ProtectedRoute path='/test' component={TestPage} noRedirect exact />
                  <AccountManagerRoute path='/businesses' component={BusinessesPage} />
                  <AccountManagerRoute
                    path='/businesses/:uuid'
                    component={ripRouteProps(
                      BusinessPage,
                      ['business', 'listings'],
                      ['uuid']
                    )} />
                  <BusinessRoute path='/listing' component={ListingPage} />
                  <SalesRoute path='/events/all' component={EventsPage} />
                  <SalesRoute
                    path='/events/:uuid'
                    component={ripRouteProps(EventPage, ['event'], ['uuid'])}
                    />
                  <SalesRoute path='/register' component={AddBusinessPage} />
                  <SalesRoute path='/register/success' component={AddBusinessSuccessPage} />
                  <AdminRoute path='/sales-team' component={SalesTeamPage} />
                </SiteWrapper>
              </Route>
            </Switch>
          </Router>
        </StripeProvider>
      </ApolloProvider>
    </AuthProvider>
  );
}

export default App;


import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Card, Table, Form, Grid, Icon, Button, Dimmer } from 'tabler-react';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';

function GlobalFilterInput({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length

  return (
    <Form.Input
      className="mt-3"
      icon="search"
      placeholder={`${count} records...`}
      value={globalFilter || ''}
      onChange={e => {
        setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      position="append"
      />
  );
}

const TablerTable = (props) => {
  const {
    title, columns, data = [], query, filter, onChangeSearch, searchPlaceholder,
    filterDisabled, loading, ...rest
  } = props;
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter }
  } = useTable({columns, data}, useGlobalFilter, useSortBy, usePagination);
  const [index, setIndex] = useState(pageIndex + 1);

  useEffect(() => {
    setGlobalFilter(filter);
  }, [filter]);

  const onChangeIndex = useCallback(e => {
    const index = parseInt(e.target.value, 10);

    if (index >= 0 && index < pageCount) {
      gotoPage(index);
    }
  }, [gotoPage, pageCount]);

  const onChangePageSize = useCallback(e =>
    setPageSize(parseInt(e.target.value, 10)),
    [setPageSize]
  );

  const PageSizeSelect = useMemo(() => {
    return () => (
      <Form.Select value={pageSize} onChange={onChangePageSize}>
        { [5, 10, 20, 25, 50, 100].map((v, i) => (
          <option key={i} value={v}>{v} Rows</option>
        ))}
      </Form.Select>
    );
  }, [pageSize, onChangePageSize]);

  return (
    <Card>
      <Card.Header>
        <Card.Title>{title}</Card.Title>
        <Card.Options>
          <Form.Group>
            { !filterDisabled && 
              <GlobalFilterInput
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                />
            }
          </Form.Group>
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <Dimmer active={loading} loader={loading}>
          <Table {...getTableProps()} {...rest}>
            <Table.Header>
              {headerGroups.map(headerGroup => (
                <Table.Row {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <Table.ColHeader {...column.getHeaderProps()}>
                      <a {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {column.isSorted ?
                          (column.isSortedDesc ?
                            <Icon name='arrow-down' /> :
                            <Icon name='arrow-up' />) : ''
                        }
                      </a>
                    </Table.ColHeader>
                  ))}
                </Table.Row>
              ))}
            </Table.Header>
            <Table.Body>
              {page.map(
                (row, i) =>
                  prepareRow(row) || (
                    <Table.Row {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return <Table.Col {...cell.getCellProps()}>{cell.render('Cell')}</Table.Col>
                      })}
                    </Table.Row>
                  )
              )}
            </Table.Body>
          </Table>
        </Dimmer>
      </Card.Body>
      <Card.Footer>
          <Grid>
            <Grid.Row>
              <Grid.Col auto>
                <Button.List>
                  <Button disabled={!canPreviousPage} onClick={() => gotoPage(0)}>First</Button>
                  <Button disabled={!canPreviousPage} onClick={previousPage}>Prev</Button>
                </Button.List>
              </Grid.Col>
              <Grid.Col>
                <Grid.Row alignItems='center' justifyContent='center'>
                  <Grid.Col>
                    <Form.InputGroup>
                      <Form.InputGroupPrepend>
                        <Form.InputGroupText>Page</Form.InputGroupText>
                      </Form.InputGroupPrepend>
                      <Form.Select value={pageIndex} onChange={onChangeIndex}>
                        { [...Array(pageCount)].map((p, i) => (
                          <option key={i} value={i}>{i + 1}</option>
                        ))}
                      </Form.Select>
                      <Form.InputGroupAppend>
                        <Form.InputGroupText>of {pageCount}</Form.InputGroupText>
                      </Form.InputGroupAppend>
                    </Form.InputGroup>
                  </Grid.Col>
                  <Grid.Col>
                    <PageSizeSelect />
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
              <Grid.Col auto>
                <Button.List>
                  <Button disabled={!canNextPage} onClick={nextPage}>Next</Button>
                  <Button disabled={!canNextPage} onClick={() => gotoPage(pageCount - 1)}>Last</Button>
                </Button.List>
              </Grid.Col>
            </Grid.Row>
          </Grid>
      </Card.Footer>
    </Card>
  );
};

export default TablerTable;


import React, { Component } from 'react';
import { Page, Form, Button } from 'tabler-react';
import moment from 'moment';
import { debounce } from 'throttle-debounce';
import TablerTable from '../components/Table/TablerTable.js';
import { Link } from 'react-router-dom';
import UpdateEventCard from '../components/UpdateEventCard.js';
import { confirm } from '../components/Dialog/Confirm.js';

import { searchEvents, addEvent } from '../API.js';

const columns = [{
  id: 'company',
  Header: 'Name',
  accessor: 'title',
  Cell: ({cell: {value}, row}) => (
    <Link to={{
        pathname: `/events/${row.original.uuid}`,
        state: { event: row.original }
      }}>
      {value}
    </Link>
  )
}, {
  id: 'account',
  Header: 'Account',
  Cell: ({row}) => {
    let text = 'n/a';
    const { account } = row.original;

    if (account) {
      if (account.name) {
        text = account.name.first + ' ' + account.name.last;
      }

      if (account.company) {
        if (text.length) {
          text += ` (${account.company})`;
        } else {
          text = account.company;
        }
      }
    }

    return text;
  }
}, {
  id: 'starts',
  Header: 'Start',
  accessor: 'starts_at',
  Cell: ({cell: {value}}) => moment(value).format('MMM Do, YYYY @ h:mma')
}, {
  id: 'end',
  Header: 'End',
  accessor: 'ends_at',
  Cell: ({cell: {value}}) => moment(value).format('MMM Do, YYYY @ h:mma')
}];

export default class EventsPage extends Component {
  state = {
    events: [],
    query: '',
    loading: 0
  }

  componentDidMount() {
    this.search(this.state.query);
  }

  componentWillUnmount() {
    if (this.searchDebounced) {
      this.searchDebounced.cancel();
    }
  }

  onChangeSearch = (e) => {
    const { value } = e.target;
    const { query } = this.state;

    if (value !== query || (value && value.length !== 1)) {
      this.searchDebounced(value);
    }

    this.setState({ query: value });
  }

  search = query => {
    this.setState({ loading: this.state.loading + 1 });

    searchEvents(query).then(({events}) => {
      this.setState({ events, loading: this.state.loading - 1 });
    }).catch(() => this.setState({ loading: this.state.loading - 1 }));
  }

  searchDebounced = debounce(500, this.search)

  decrementLoading = () => {
    this.setState({
      loading: this.state.loading - 1
    });
  }

  onAdd = (event) => {
    this.setState({
      events: [
        ...this.state.events,
        event
      ],
      showForm: false
    });
  }

  onUpdateEvent = (event, start, finish) => {
    start();
    addEvent(event).then(({ event }) => {
      this.onAdd(event);
        finish(true);
    }).catch(finish);
  }

  showForm = () => this.setState({ showForm: true })
  hideForm = () => this.setState({ showForm: false })

  render() {
    const { query, events, loading } = this.state;
    const isLoading = loading > 0;

    return (
      <Page.Content title='Events'>
        <UpdateEventCard onUpdateEvent={this.onUpdateEvent} />
        <TablerTable
          title='Event List'
          loading={isLoading}
          query={query}
          onChangeSearch={this.onChangeSearch}
          searchPlaceholder='Search by Name or Location...'
          data={events}
          columns={columns}
          defaultPageSize={10}
          />
      </Page.Content>
    );
  }
}
